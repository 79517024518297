import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createTimelineWidgetsComponent } from "../widgets/TimelineWidgetsComponent";
import { rest } from "../../../auto/js/services";
import { loadCaseData } from "../utils";
import { CaseComponent } from "../widgets/CaseComponent";
import { classerComplaint, investigateComplaint, loadComplaintMtlbFormData } from "../../../auto/js/forms/complaintMtlb/ComplaintMtlbApi";
import { whoami } from "../../../auto/js/users/UserInfo";
import { cleanContextualOptions, setContextualOptions } from "../../../auto/js/widgets";
import { set } from "lodash";
import { displayComplaintMtlbList } from "../../../auto/js/lists/complaintMtlb/ComplaintMtlbList";
import { classerRegistrePoursuit, poursuivreRegistrePoursuit } from "../../../auto/js/forms/registrePoursuite/RegistrePoursuiteApi";
import { displayNewSoitTransmisForm } from "../../../auto/js/forms/soitTransmis/NewSoitTransmisForm";
import { displayNewRegistreOppositionForm } from "../../../auto/js/forms/registreOpposition/NewRegistreOppositionForm";
import { displayCaseDataList } from "../../../auto/js/lists/caseData/CaseDataList";
import { displayNewMandatDepotForm } from "../../../auto/js/forms/mandatDepot/NewMandatDepotForm";
import { displayNewMandatAmenerForm } from "../../../auto/js/forms/mandatAmener/NewMandatAmenerForm";
import { displayNewJugementForm, displayReadJugementForm } from "../../../auto/js/forms/jugement/NewJugementForm";
import { displayNewAudienceForm, displayReadAudienceForm } from "../../../auto/js/forms/audience/NewAudienceForm";
import { displayReadRegistreAffectationForm } from "../../../auto/js/forms/registreAffectation/NewRegistreAffectationForm";

const caseTimelineFields = (whoami) => { return [
	{
		name: 'plaintDetails',
		title: "Entrée dans le registre d'arrivée",
		columns: ['subjectOfComplaint', 'incidentCategory', 'incidentDate', 'incidentTime', 'mtlbStatus'],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:1,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	},
	{
		name: 'affectation',
		title: "Entrée dans le registre d'affectation",
		columns: [],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:2,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	},
	{
		name: 'poursuite',
		title: "Entrée dans le registre de poursuite",
		columns: [],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:3,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	},
	{
		name: 'soitTransmis',
		title: "Soit transmis",
		columns: [],
		loadData: async (id) => loadComplaintMtlbFormData(id),
		type: "EVENT",
		x:1,
		y:4,
		layout:"col-md-12",
		footerElements: ['created', 'lastModified']
	}
]}

class TimelineComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
    }

	loadTimelineData = async (id, gui) => {
		return await rest.read('case-data', id).then(response => {
			this.setContext(JSON.parse(response.data), id, gui)
			return response;
		});
	}
	
	setContext = async (data, id, gui) => {
		let tagFilter = {};
		tagFilter["case-tags"] = {caseId: id};
		rest.search('case-tags', tagFilter).then((response) => {
			response.forEach(element => {
				if (element.key === "AFFAIRE_STATUS")
					if (element.value === "PV_SUBMITTED") {
						let doc = data.documents.filter((doc) => doc.documentType === "REGISTRE_POURSUITE")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													"nouveau-st": {
														label: "Nouveau Soit Transmis",
														do: (gui) => displayNewSoitTransmisForm(gui, {ra: id, destination: doc.registrePoursuite.unit})
													},
													...(!whoami().roles.includes('GREFFIER_PENAL_BO') && !whoami().roles.includes('GREFFIER_ADMIN')) && {
													"poursuivre": {
														label: "Poursuivre",
														do: () => poursuivre(doc.registrePoursuite.id).then(() => {
															(gui) => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CASE_OPENED"]})
															cleanContextualOptions({"affaire-cours":{"affaire-cours":{}}})
														})
													},
													"classer": {
														label: "Classer",
														do: () => classer(doc.registrePoursuite.id).then(() => {
															displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
															cleanContextualOptions({"ac":{"ac":{}}})
														})
													}
													}
												}
											}
										}
									}
								}	
							})	
					}
					else if (element.value === "SUBMITTED") {
						let doc = data.documents.filter((doc) => doc.documentType === "REGISTRE_ARRIVEE")[0];
						let affDoc = data.documents.filter((doc) => doc.documentType === "REGISTRE_AFFECTATION")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(!whoami().roles.includes('GREFFIER_PENAL_BO') && !whoami().roles.includes('GREFFIER_ADMIN')) && {
														"investigate": {
															label: "Enquêter",
															do: () => investigate(doc.complaintMtlb.id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
																cleanContextualOptions({"na":{"na":{}}})
															})
														},
														"classer": {
															label: "Classer",
															do: () => classerAffaire(doc.complaintMtlb.id).then(() => {
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CLOSED"]})
																cleanContextualOptions({"ac":{"ac":{}}})
															})
														}
													},
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN')) && {
														"affecter": {
															label: "Affecter un procureur ou un substitut",
															do: (gui) => gui.goTo(displayReadRegistreAffectationForm(()=>{
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
																cleanContextualOptions({"na":{"na":{}}})
															}), affDoc.id)
														}
													}

												}
											}
										}
									}
								}	
							})
					}
					else if (element.value === "WAIT_FOR_PV") {
						let doc = data.documents.filter((doc) => doc.documentType === "REGISTRE_POURSUITE")[0];
						if (doc)
							setContextualOptions({
								"na": {
									"na": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													"nouveau-st": {
														label: "Nouveau Soit Transmis",
														do: (gui) => displayNewSoitTransmisForm(gui, {ra: id, destination: doc.registrePoursuite.unit})
													},
													"nouveau-md": {
														label: "Nouveau Mandat de dépôt",
														do: (gui) => displayNewMandatDepotForm(gui, {caseId: id})
													},
													...(whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN')) && {
														"affecter": {
															label: "Affecter un procureur ou un substitut",
															do: (gui) => gui.goTo(displayReadRegistreAffectationForm(()=>{
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})
																cleanContextualOptions({"na":{"na":{}}})
															}), affDoc.id)
														}
													}
												}
											}
										}
									}
								}	
							})
					}
					else if (element.value === "JUDGED") {
						let doc = data.documents.filter((doc) => doc.documentType === "JUGEMENT")[0];
						if (doc)
							setContextualOptions({
								"aj": {
									"aj": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(whoami().roles.includes('GREFFIER_OA')) && {
													"opposition": {
														label: "Nouvel Acte d'Opposition",
														do: (gui) => displayNewRegistreOppositionForm(gui, doc.jugement)
													},
													}
												}
											}
										}
									}
								}	
							})
					}
					else if (element.value === "CASE_OPENED") {
						let doc = data.documents.filter((doc) => doc.documentType === "AUDIENCE")[0];
						if (doc)
							setContextualOptions({
								"affaire-cours": {
									"affaire-cours": {
										submenu: {
											"actions": {
												label: "Actions",
												options: {
													...(whoami().roles.includes('SIEGE')) && {
													"amener": {
														label: "Nouveau Mandat d'amener",
														do: (gui) => {
															let mdFormData = {caseId: id}
															mdFormData.president = whoami().lastname + ' ' + whoami().firstname;
															displayNewMandatAmenerForm(gui, mdFormData)
														}
													},
													...(whoami().roles.includes('GREFFIER_CC')) && {
													"audience": {
														label: "Programmer une audience",
														do: (gui) => gui.goTo(displayReadAudienceForm(), doc.id)
													}
													}
													}
												}
											}
										}
									}
								}	
							})
							let jDoc = data.documents.filter((doc) => doc.documentType === "JUGEMENT")[0];
							if (jDoc)
								setContextualOptions({
									"affaire-cours": {
										"affaire-cours": {
											submenu: {
												"actions": {
													label: "Actions",
													options: {
														...(whoami().roles.includes('SIEGE')) && {
															"amener": {
																label: "Nouveau Mandat d'amener",
																do: (gui) => {
																	let mdFormData = {caseId: id}
																	mdFormData.president = whoami().lastname + ' ' + whoami().firstname;
																	displayNewMandatAmenerForm(gui, mdFormData)
																}
															},
															...(whoami().roles.includes('GREFFIER_CC')) && {
															"audience": {
																label: "Programmer une audience",
																do: (gui) => gui.goTo(displayReadAudienceForm(()=>{
																	displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["CASE_OPENED"]})
																	cleanContextualOptions({"affaire-cours":{"affaire-cours":{}}})
																}), doc.id)
															}
															}
														},
														...(whoami().roles.includes('SIEGE')) && {
														"jugement": {
															label: "Téléverser un jugement",
															do: (gui) => gui.goTo(displayReadJugementForm(()=>{
																displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["JUDGED"]})
																cleanContextualOptions({"aj":{"aj":{}}})
															}), jDoc.id)
														},
														}
														}
													}
												}
											}
										}
									}	
								)
						}

	
			});
		})
	}


	render() {
		let CaseTimeline = createTimelineWidgetsComponent(caseTimelineFields(whoami()));

		return (
		<>
			<CaseComponent name={name} disabled={true} loadData={() => loadCaseData(this.props.id)} />
			<CaseTimeline gui={this.props.gui} ref={this.myRef} key={this.props.key} loadData={async () => this.loadTimelineData(this.props.id, this.props.gui)} />
		</>
		)
	}

}


export const displayCaseDataTimeline = (gui, id)=> {
		const readCaseDataTimeline = (onFinish) => (id) => {
		let uuid = uuidv4();
		return {
			uuid, view: () => <TimelineComponent key={uuid} gui={gui} id={id} onFinish={onFinish}/>
		};
	}
	gui.goTo(readCaseDataTimeline(), id)
}



const poursuivre = async (id) => {
	return poursuivreRegistrePoursuit(id);
}

const classer = async (id) => {
	return classerRegistrePoursuit(id);
}

const investigate = async (id) => {
	return investigateComplaint(id);
}

const classerAffaire = async (id) => {
	return classerComplaint(id);
}