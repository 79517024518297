import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";
import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, CAN_I_SWITCH, DELETE_ATTACHMENT_EVENT, FILE_UPLOADED_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { AlertDialog } from "../../widgets";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { saveComplaintMtlbForm, updateComplaintMtlbForm, deleteComplaintMtlbForm, loadComplaintMtlbFormData } from "./ComplaintMtlbApi";
import { buildComplaintMtlbFormEmptyObject, complaintMtlbFields, newComplaintMtlbFormForm2Dto } from "../../../../main/js/forms/complaintMtlb/ComplaintMtlbFormCommon";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FileInput } from "../../widgets/FileInput";
import { createTableComponent } from "../../widgets/TableComponent";
import { Section } from "../../../../auto/js/widgets/Section";
import { displayComplaintMtlbList } from "../../lists/complaintMtlb/ComplaintMtlbList";
import { whoami } from "../../users/UserInfo";
import { displayCaseDataList } from "../../lists/caseData/CaseDataList";

export class ComplaintMtlbFormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = (id) => {
		deleteComplaintMtlbForm(id);
		this.state.closeRequested(true);
	}

	render() {
	let NewComplaintMtlbForm = createFormComponent(complaintMtlbFields(whoami()));

		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<NewComplaintMtlbForm ref={this.myRef} key={this.props.key} id={this.props.id} loadData={async () => loadComplaintMtlbFormData(this.props.id)} onSubmit={this.props.onSubmit} buttons={getButtons} />
		</>
		)
	}

}


export const displayNewComplaintMtlbForm = (gui, formData) => {
	const readNewComplaintMtlbForm = (onFinish) => (id) => {
		let uuid = uuidv4();
		return { 
			uuid, view: () => <ComplaintMtlbFormComponent key={uuid} id={id} onSubmit={(onFinish)?(data) => save(data).then(() => onFinish()):save} />
		}
	}
	let data;
	if (!formData) {
		data = buildComplaintMtlbFormEmptyObject();
		data.draft = true;
		saveComplaintMtlbForm(data).then((response) => {
			gui.goTo(readNewComplaintMtlbForm(() => displayCaseDataList(gui, {caseTagskeyList: ["AFFAIRE_STATUS"], caseTagsvalueList:["SUBMITTED", "WAIT_FOR_PV", "PV_SUBMITTED"]})), response)
		});
	}
	else {
		data = _.clone(formData);
		data.draft = true;
		newComplaintMtlbFormForm2Dto(formData, data)
		if (formData.image) {
			if (!formData.image.isEmpty) {
				let base64Image = formData.image.url;
				fetch(base64Image)
			  .then(res => res.blob())
			  .then(blob => {      
					let faceMimeType = "image/png";
					data.face = blob;
					data.faceMimeType = faceMimeType;			   
					const reader = new FileReader();
					reader.onloadend = () => {
					  	data.image.url = reader.result;
						saveComplaintMtlbForm(data).then((response) => {
							gui.goTo(readNewComplaintMtlbForm(() => displayComplaintMtlbList(gui)), response)
						});
					};
					reader.readAsDataURL(blob); 
				});
			} else {
				saveComplaintMtlbForm(data).then((response) => {
					gui.goTo(readNewComplaintMtlbForm(() => displayComplaintMtlbList(gui)), response)
				});
			}
		} else {
			saveComplaintMtlbForm(data).then((response) => {
					gui.goTo(readNewComplaintMtlbForm(() => displayComplaintMtlbList(gui)), response)
				});
		}
	}
}

const save = async (formData) => {
	formData.draft = false;
	return updateComplaintMtlbForm(formData);
}

const getButtons = () => {
	return <button style={{ minWidth: '5rem' }} type="submit"> {t`Submit`} </button>
}
