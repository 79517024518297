import React from "react";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";

export const customRecordsForm2Dto = (formData, dto) => {

}

export const customRecordsDto2Form = (dto, form) => {

}

export const Separator = () => {
    return (
        <>
        <div className="col-md-12">
            <hr style={{ borderTop: '2px solid #8cadab' }} />
        </div>
        </>
    );
    };

    export const recordsListFields = [
        {
            title: "ID",
            field: "id",
        },
        {
            title: "Nom",
            field: "lastname",
        },
        {
            title: "Deuxième prénom",
            field: "othernames",
        },
        {
            title: "Prénom",
            field: "firstname",
        },
        {
            title: "Numéro d'identité",
            field: "idNumber",
        }
    ]

export const parentsSearchFormfields = [
    /* {name: "exactId", type: "number", x:1, y: 1, layout:"col-md-4"},
    {name: "idSeparator", type: "custom", x:1, y:2, layout:"col-md-12", component: (name, disabled) => <Separator />}, */

    {name: "firstname", type: "text", x:1, y:5, layout:"col-md-6"},
    {name:"firstnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:5, layout:"col-md-6 pt-5", defaultValue:"startsWith"},
        
    {name: "othernames", type: "text", x:1, y:4, layout:"col-md-6"},
    {name:"othernamesCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:4, layout:"col-md-6 pt-5", defaultValue:"startsWith"},
    
    {name: "lastname", type: "text", x:1, y: 3, layout:"col-md-6"},
    {name:"lastnameCondition", options: [
        {"name":"fuzzy","label":"Fuzzy"},
        {"name":"exact","label":"Exact"},
        {"name":"startsWith","label":"Startswith"},
    ], type: "radio", x:2, y:3, layout:"col-md-6 pt-5",defaultValue:"startsWith"},
    
    {name:"birthBox", label: "Birth Date", components: [
        {name: "rangeBirthdate",label:"exactbirthdate", type: "checkbox", x:1, y:1, layout:"col-md-12"},
        {name: "exactBirthdate", type: "date", x:1, y: 2, layout:"col-md-6", display: (data)=>{
            return !data.rangeBirthdate;
        }},
        
        {name: "birthdateStart", type: "date", x:1, y: 3, layout:"col-md-6",display: (data)=>{
            return data.rangeBirthdate;
        }},
        {name: "birthdateEnd", type: "date", x:2, y: 3, layout:"col-md-6", display: (data)=>{
            return data.rangeBirthdate;
        }},
    ], type: "box", x:1, y:9, layout:"col-md-11 ms-2 mt-2 pt-1 pb-2"},
    {
        name: "addressBox", label: "Address", components: [
            {
                name: "address", type: "custom", x: 1, y: 2, layout: "col-md-12",
                component: (name, disabled) => <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
            },
        ], type: "box", x: 2, y: 10, layout: "col-md-11 ms-2 mt-2 pt-1 pb-2"
    }
];
   
export const transformFormData = (formData) => {
    const columns = [];
    
    const addColumn = (name, type, value, condition) => {
        columns.push({
        name,
        type,
        [type === 'date' ? 'dateValue' : (type === 'text' ? 'textValue' : 'longValue')]: value,
        searchType: condition ? condition : null,
        });
    };
    (formData.firstname !== "") && addColumn('firstname', 'text', formData.firstname, formData.firstnameCondition);
    
    (formData.othernames !== "" && formData.othernames !== null) && addColumn('othernames', 'text', formData.othernames, formData.othernamesCondition);
    
    (formData.lastname !== "" && formData.lastname !== null) && addColumn('lastname', 'text', formData.lastname, formData.lastnameCondition);
    
/*     (formData.address && formData.address !== null) && addColumn('address', 'text', formData.address, formData.address ? "startsWith" : null);
 *//*     (formData.exactId !== "") && addColumn('id', 'number', formData.exactId, null);
 */    const birthdateRange = formData.birthdateStart && formData.birthdateEnd
        ? {
                min: formData.birthdateStart ? formData.birthdateStart : null,
                max: formData.birthdateEnd ? formData.birthdateEnd : null,
            }
        : null;
    
    (birthdateRange != null || formData.exactBirthdate != null) && addColumn('birthdate', 'date', formData.exactBirthdate, null);
    if ((birthdateRange != null || formData.exactBirthdate != null))
        columns[columns.length - 1].dateRange = birthdateRange;
    
    return { columns, caseSensitiveSearch: formData.caseSensitiveSearch };
};
