import React, { useState } from "react";
import { useField } from "formik";
import { FormLabel } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import { rest, t } from "../../../auto/js/services";
import { ImageViewer, buildEmptyObject, createFormComponent } from "../../../auto/js/widgets";
import { formatDate } from "../../../auto/js/utils";
import { geoDataMetadataLoader } from "../../../auto/js/metadata/GeoDataMetadataLoader";
import { Button } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { birthRecordColumns, parentsSearchFormfields, transformFormData, substitutFormfields, substitutRecordColumns, transformSubstitutFormData } from "../forms/CivilRecordFormCommon";
import { DraggableFilterDialog } from "./DraggableFilterComponentUpgrade";
import { formState } from "../../../auto/js/forms/FormState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { loadSubstitutData } from "../utils";


export const CaseComponent = ({...props}) => {
    /* const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers; */


    return(
      <Case loadData={props.loadData} />
      )
}

class Case extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: undefined,
        loading: true,
        loadingMessage: ''
      }
      this.props.loadData().then((data) => {
        //formState.setState(data);
        this.setState({ data: data, loading: false })
      });
	  }

    componentDidUpdate(prevProps) {
      this.props.loadData().then((data) => {
        //formState.setState(data);
        if (data.id !== this.state.data.id)
          this.setState({ data: data, loading: false })
      });
    }
    
    render() {
        let plaignants = [];
        if (this.state.data) {
            this.state.data.plaignants.forEach(element => {
                plaignants.push(
                    <>
                    <p>{element}</p> 
                    </>
                )
            });
        }

        let victimes = [];
        if (this.state.data) {
            this.state.data.victimes.forEach(element => {
                victimes.push(
                    <>
                    <p>{element}</p> 
                    </>
                )
            });
        }

        let misCauses = [];
        if (this.state.data) {
            this.state.data.misCauses.forEach(element => {
                misCauses.push(
                    <>
                    <p>{element}</p> 
                    </>
                )
            });
        }
        return (
            <>
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.loadingMessage}
            >
            {(this.state.data)?
            <div class="row rv5info">
             <div class="col-md-4"><div class="results" style={{paddingLeft:"0px"}}><h4>{t`Informations sur le dossier`}</h4>
                 <p>{t`Numéro électronique de RP`}: {this.state.data.rp}</p>
                 <p>{t`Numéro manuel de RP`}: {this.state.data.legacyId}</p>
             </div>
             </div>
            
             <div class="col-md-3">
                <div class="results">
                  <h4>{t`Plaignants`}</h4>
                  {plaignants}
                </div>
            </div>
            <div class="col-md-2">
                <div class="results">
                  <h4>{t`Victimes`}</h4>
                  {victimes}
                </div>
            </div>
            <div class="col-md-3">
                <div class="results">
                  <h4>{t`Mis en causes`}</h4>
                  {misCauses}
                </div>
            </div>
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                <p style={{fontStyle: "italic", color: "#898c8e"}}>{t`Numéro électronique de dossier`}: {this.state.data.id}</p>
            </div>
            </div>:
            <div className="ValidationError">{t`Case not found`}</div>
            }
            </LoadingOverlay>
            </>
        )    
    }
}