import React from "react";

import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { ComplainantContactPreferences, COMPLAINANT_CONTACT_PREFERENCES_EMAIL } from "../../../../auto/js/metadata/ComplainantContactPreferences"
import { IncidentCategory, INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN } from "../../../../auto/js/metadata/IncidentCategory"
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { DELETE_ATTACHMENT_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { Section } from "../../../../auto/js/widgets/Section";
import { AdvancedSearchPersonComponent } from "../../widgets/AdvancedSearchPersonComponent";
import { createRecord, getRecordsOptions, loadPersonData, readRecord } from "../../utils";
import TemplateComponent from "react-mustache-template-component";
import { setContextualOptions } from "../../../../auto/js/widgets";
import { whoami } from "../../../../auto/js/users/UserInfo";
import { displayNewSoitTransmisForm } from "../../../../auto/js/forms/soitTransmis/NewSoitTransmisForm";
import AutocompleteListSelector from "../../../../auto/js/widgets/AutocompleteDynamicListSelectorBackup";
import AutocompleteDynamicListSelector from "../../../../auto/js/widgets/AutocompleteDynamicListSelectorBackup";
import AutocompleteDynamicListSelectorField from "../../widgets/AutocompleteDynamicListSelectorField";

const incidentCategory = {
	1: "CONSUMER_RIGHTS",
	2: "ENVIRONMENTAL_CONCERNS",
	3: "PUBLIC_SAFETY",
	4: "GOVERNMENTAL_CONCERN"
}

const unit = {
	1: "POL",
	2: "GEN",
	3: "GAC",
}

const complainantContactPreferences = {
	1: "EMAIL",
	2: "PHONE"
}

export const registrePoursuiteFields = (whoami) => {return [
	{name: "id", label: "Numéro électronique dans le registre de poursuite", type: "number", x: 1, y: 1, layout: "col-md-6", disabled: true},
//    {name: "folderId", type: "number", x: 1, y: 2, layout: "col-md-3", disabled: true},
	{name: "legacyId", type: "text", x: 1, y: 3, layout: "col-md-6",  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null },
	{name: "plaignants", type: "custom", x:1, y:4, layout: "col-md-6", component: (name, disabled) => <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Plaignants`} />},
	{name: "complaintType", type: "select", x:1, y: 8, layout:"col-md-6",  metadata: () => incidentCategory,  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') || whoami.roles.includes('GREFFIER_PENAL_BO') && {disabled: true}:null},
	{ name: "complaintDate", type: "date", x:1, y:9,  layout:"col-md-6",  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null},
	{ name: "complaintTime", type: "time", x:2, y:9,  layout:"col-md-6",  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null },
    {name: "pvId", type: "text", x: 1, y: 10, layout: "col-md-3" },
    {name: "pv", type:"file", x:1, y:11, layout:"col-md-12", label: "Pièces jointes du pv",
		uploadUrl: (id) => getPvUploadUrl(id), 
		previewUrl: (id) => getPvPreviewUrl(id),
		loadData: async (id) => loadPv(id), 
		handleDelete:(id) => handlePvDelete(id), 
		handleClick: (id) => handlePvClick(id),
		updateFileData: (data) => updatePvFileData(data)
	},
    {name: "soitTransmisId", type: "text", x: 1, y: 13, layout: "col-md-3",  ...(whoami)?whoami.roles.includes('OPJ') && {disabled: true}:null },
    {name: "soitTransmis", type:"file", x:1, y:14, layout:"col-md-12", label: "Pièces jointes du soit transmis",
		uploadUrl: (id) => getSoitTransmitFileUploadUrl(id), 
		previewUrl: (id) => getSoitTransmitFilePreviewUrl(id),
		loadData: async (id) => loadSoitTransmitFile(id), 
		handleDelete:(id) => handleSoitTransmitFileDelete(id), 
		handleClick: (id) => handleSoitTransmitFileClick(id),
		updateFileData: (data) => updateSoitTransmitFileFileData(data),
		...(whoami)?whoami.roles.includes('OPJ') && {disabled: true}:null
	},
    {name: "unit", type: "select", x:1, y: 15, layout:"col-md-6",  metadata: () => unit,  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null},
    {name: "victimes", type: "custom", x:1, y:5, layout: "col-md-12", component: (name, disabled) =>
		<AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Victimes`} />
		,  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null},
    {name: "misCauses", type: "custom", x:1, y:6, layout: "col-md-12", component: (name, disabled) => <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Mis en Causes`} />,  ...(whoami)?whoami.roles.includes('OPJ') || whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null},
    {name: "Autres", type: "custom", x: 1, y: 16, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
    {name: "attachments", lable:"attachments", type:"file", x:1, y:17, layout:"col-md-12",label: "Pièces jointes",
		uploadUrl: (id) => getUploadUrl(id), 
		previewUrl: (id) => getPreviewUrl(id),
		loadData: async (id) => loadAttachment(id), 
		handleDelete:(id) => handleDelete(id), 
		handleClick: (id) => handleClick(id),
		updateFileData: (data) => updateFileData(data),
		...(whoami)?whoami.roles.includes('OPJ') && {disabled: true}:null
	},
]}

const getUploadUrl = (id) => {
	return getServiceUri() + 'registre-poursuite/attachments' + '/' + id;
}

const getPreviewUrl =  (id) => {
	return getServiceUri() + 'registre-poursuite/attachments' + '/preview/' + id + '/';
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'registre-poursuite/attachments' + '/' + data.id, "PUT", filter);
}

const handleClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'registre-poursuite/attachments' + '/' + id + '/' + token;
};
const handleDelete = (id) => {
	rest.delete('registre-poursuite/attachments', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadAttachment = async (id) => {
	let filter = {and: true};
	filter['registre-poursuite-attachments'] = {};
	filter['registre-poursuite-attachments']['registrePoursuiteId'] = id;
	return rest.search('registre-poursuite/attachments', filter)
}

const getPvUploadUrl = (id) => {
	return getServiceUri() + 'registre-poursuite/pv-file' + '/' + id;
}

const getPvPreviewUrl =   (id) => {
	return getServiceUri() + 'registre-poursuite/pv-file' + '/preview/' + id + '/';
}

const updatePvFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'registre-poursuite/pv-file' + '/' + data.id, "PUT", filter);
}

const handlePvClick =  async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'registre-poursuite/pv-file' + '/' + id + '/' + token;
};
const handlePvDelete = (id) => {
	rest.delete('registre-poursuite/pv-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadPv = async (id) => {
	let filter = {and: true};
	filter['registre-poursuite-pv-file'] = {};
	filter['registre-poursuite-pv-file']['registrePoursuiteId'] = id;
	return rest.search('registre-poursuite/pv-file', filter)
}

const getSoitTransmitFileUploadUrl = (id) => {
	return getServiceUri() + 'registre-poursuite/soit-transmit-file' + '/' + id;
}

const getSoitTransmitFilePreviewUrl =  (id) => {
	return getServiceUri() + 'registre-poursuite/soit-transmit-file' + '/preview/' + id + '/';
}

const updateSoitTransmitFileFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'registre-poursuite/soit-transmit-file' + '/' + data.id, "PUT", filter);
}

const handleSoitTransmitFileClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'registre-poursuite/soit-transmit-file' + '/' + id + '/' + token;
};
const handleSoitTransmitFileDelete = (id) => {
	rest.delete('registre-poursuite/soit-transmit-file', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadSoitTransmitFile = async (id) => {
	let filter = {and: true};
	filter['registre-poursuite-soit-transmit-file'] = {};
	filter['registre-poursuite-soit-transmit-file']['registrePoursuiteId'] = id;
	return rest.search('registre-poursuite/soit-transmit-file', filter)
}

export const form2dto = (formData, dto) => {
	/* if (dto.complaintType !== null)
		dto.complaintType = incidentCategory[dto.complaintType]; */
	if (formData.complaintTime != null && typeof(formData.complaintTime) != 'string') {
		const date = new Date(formData.complaintTime)
		let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.complaintTime = hour + ":" + minute;
	}

	if (formData.victimes) {
		rest.purge('victimes', formData.folderId).then(() => {
			Object.keys(formData.victimes).forEach(element => {
				let names = formData.victimes[element].split(' ');
				let lastname ="";
				let firstname = names[0];
				if (names.length > 1)
					lastname = names[names.length - 1];
				let data = {folderId: formData.folderId, recordsId: element, firstname: firstname, lastname: lastname}
				rest.create('victimes', data);
			});
		})
		
	}

	if (formData.misCauses) {
		rest.purge('mis-cause', formData.folderId).then(() => {
			Object.keys(formData.misCauses).forEach(element => {
				let names = formData.misCauses[element].split(' ');
				let lastname ="";
				let firstname = names[0];
				if (names.length > 1)
					lastname = names[names.length - 1];
				let data = {folderId: formData.folderId, recordsId: element, firstname: firstname, lastname: lastname}
				rest.create('mis-cause', data);
			});
		})
	}
		

	if (formData.plaignants) {
		rest.purge('plaignants', formData.folderId).then(() => {
			Object.keys(formData.plaignants).forEach(element => {
				let names = formData.plaignants[element].split(' ');
				let lastname ="";
				let firstname = names[0];
				if (names.length > 1)
					lastname = names[names.length - 1];
				let data = {folderId: formData.folderId, recordId: element, firstname: firstname, lastname: lastname}
				rest.create('plaignants', data);
			});
		})
	}
}

export const dto2form = (dto) => {
	let form = dto;
	if (dto.complaintTime !== null) {
		dto.complaintTime = new Date().setHours(dto.complaintTime[0], dto.complaintTime[1]);
	}

/* 	rest.read('forms/avis-de-classement/registre-poursuite', form["id"]).then(async avisDeClassement => {
		setContextualOptions({
			"na": {
				"na": {
					submenu: {
						"actions": {
							label: "Actions",
							options: {
								...(dto.caseStatus === "CLOSED") && {
									"print-avid-de-classement": {
										label: "Imprimer Avis De Classement",
										do: printTemplate(avisDeClassement)
									}
								},
								"nouveau-st": {
									label: "Nouveau Soit Transmis",
									do: (gui) => displayNewSoitTransmisForm(gui, {ra: form.folderId, destination: form.unit})
								}
							}
						}
					}
				}
			}
		})
	}) */
	
    
  return form;
}

const printTemplate = (data) => () => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={data.templateData} />,
		copyParentStryle: false
	})
}

export const buildRegistrePoursuiteFormEmptyObject  = () => {
	return buildEmptyObject(registrePoursuiteFields(whoami()));
}

export const newRegistrePoursuiteFormForm2Dto = (form, dto) => {
	
}