import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';


import { grey } from "@mui/material/colors";
import { displayReadRecordsForm } from "../../../../auto/js/forms/records/NewRecordsForm";
import { displayRecordsList } from "../../../../auto/js/lists/records/RecordsList";

export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    rowData.gui.goTo(displayReadRecordsForm(() => displayRecordsList(rowData.gui)), rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}