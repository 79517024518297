import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getDjServicesUrl, getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    getErrorList,
    transformAdvancedSearchData,
    advancedSearchfields
} from "../../../../main/js/lists/registreAffectation/RegistreAffectationListCommon"
import { displayReadRegistreAffectationForm } from "../../forms/registreAffectation/NewRegistreAffectationForm";
import { displayCaseDataTimeline } from "../../../../main/js/timelines/CaseDataTimeline";



const registreAffectationListFields = [
    {
        title: "Numéro électronique du RP",
        field: "rpId",
    },
    {
        title: "Numéro électronique de dossier",
        field: "folderId", 
    },
    { title: "Plaignants", field: "plaignants" },
    { title: "Mis en Causes", field: "misCause" },
    { title: "Victimes", field: "victimes" },
    {
        title: "Substitut ou Procureur",
        field: "substitut",
    },
    {
        title: "complaintDate",
        field: "complaintDate",
    },
    {
        title: "complaintTime",
        field: "complaintTime",
    },
    {
        title: "Status",
        field: "caseStatus",
    },
]

const RegistreAffectationListPage = ({uuid, gui, searchData}) => {
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let RegistreAffectationTable = createTableComponent(registreAffectationListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["registre-affectation-document-dto"] = {}
        filter['and'] = true;
        filter["order-by"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["registre-affectation"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["registre-affectation-document-dto"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            return filterData(response).then(data => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }

    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }

    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <RegistreAffectationTable key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displayRegistreAffectationList = (gui) => {
	const readRegistreAffectationList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RegistreAffectationListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readRegistreAffectationList)
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
            tooltip: t`Edit`,
            onClick: (event, rowData) => {
                displayCaseDataTimeline(gui, rowData.folderId);
            }
        }
    )
    return actions;
}

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "v1/apply/search-registre-affectaion", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "v1/apply/count-registre-affectation", "POST", filter);
}

export const filterData = async (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        if (row.caseStatus != null)
            row.caseStatus = t(row.caseStatus);
        let date = row.complaintDate;
        if (date != null) {
            row.complaintDate = date[2] + "-" + date[1] + "-" + date[0];
        }
        let time = row.complaintTime;
        if (time != null) {
            row.complaintTime = time[0] + ":" + time[1];
        }
        let plaignants = '';
        let victimes = '';
        let misCauses = '';
        if (row.plaignants.length)
            plaignants = row.plaignants.join(', ');
        if (row.victimes.length)
            victimes = row.victimes.join(', ');
        if (row.misCause)
            misCauses = row.misCause.join(', ');
        row.plaignants = plaignants;
        row.victimes = victimes;
        row.misCause = misCauses;
        newRows.push(row);
    };
    return newRows;
}