import React from "react";
import { getDjServicesUrl, getServiceUri } from "../../../../auto/js/metadata";
import { COMPLAINT_MTLB_ORDER_BY_ID, COMPLAINT_MTLB_ORDER_BY_SENDER_FIRST_NAME } from "../../../../auto/js/metadata/ComplaintMtlbOrderBy";
import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";

export const registrePoursuiteListColumns = [
    {
        title: "Numéro électronique dans le registre de poursuite",
        field: "id",
    },
    {
        title: "legacyId",
        field: "legacyId",
    },
    { title: "Plaignants", field: "plaignants" },
    { title: "Mis en Causes", field: "misCause" },
    { title: "Victimes", field: "victimes" },
    { title: "pvId", field: "pvId" },
    { title: "soitTransmisId", field: "soitTransmisId" },
    { title: "Registration Date", field: "complaintDate" },
    {title: "Status", field: "status" }
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    return { columns };
};

export const advancedSearchfields = [
    
];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query) ? "registre-poursuite/advanced-search" : "v1/apply/search-registre-poursuite";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query) ? "registre-poursuite/count/advanced-search" : "v1/apply/count-registre-poursuite";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return "id"
}

export const filterData = async (defaultRows) => {
    const newRows = [];
    for (let i in defaultRows) {
        let row = defaultRows[i];
        if (row.caseStatus != null)
            row.status = t(row.caseStatus);
        let date = row.complaintDate
        if ( date !== null)
            row.complaintDate = date[2] + '-' + date[1] + '-' + date[0];
        let plaignants = '';
        let victimes = '';
        let misCauses = '';
        if (row.plaignants.length)
            plaignants = row.plaignants.join(', ');
        if (row.victimes.length)
            victimes = row.victimes.join(', ');
        if (row.misCause)
            misCauses = row.misCause.join(', ');
        row.plaignants = plaignants;
        row.victimes = victimes;
        row.misCause = misCauses;
        newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    
    return list;
}

