// DateTimePicker.js
import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import { rest } from '../../../auto/js/services';
import { getDjServicesUrl, getServiceUri } from '../../../auto/js/metadata';
import { loadingIcon } from '../../../auto/js/loadingIcon';
import { useField } from 'formik';

const formatDate = (date) => {
    let year = date[0];
    let month = (date[1] < 10)?"0" + date[1]:date[1];
    let day = (date[2] < 10)?"0" + date[2]:date[2];
    return new Date(Date.UTC(year, month - 1, day - 1)).getTime()
}

const formatTime = (date, time) => {
    let year = date[0];
    let month = (date[1] < 10)?"0" + date[1]:date[1];
    let day = (date[2] < 10)?"0" + date[2]:date[2];
    let h = (time[0] < 10)?"0" + time[0]:time[0];
    let m = (time[1] < 10)?"0" + time[1]:time[1];
    return new Date(Date.UTC(year, month - 1, day - 1, h - 1, m)).getTime();
}

const DateTimePicker = (props) => {

    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [slots, setSlots] = useState(undefined);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    if(!slots) {
        rest.request(getDjServicesUrl() + 'custom/get-available-audience', "GET").then((response) => {
            let fetchedSlots = {}
            let maxDate = new Date();
            let minDate = new Date();
            response.forEach(element => {
                if (!fetchedSlots[formatDate(element.date)]) {
                    fetchedSlots[formatDate(element.date)] = [formatTime(element.date, element.time)];
                    let date = new Date(formatDate(element.date));
                    if (date > maxDate)
                        maxDate = date;
                    if (date < minDate)
                        minDate = date;
                }
                else
                    fetchedSlots[formatDate(element.date)].push(formatTime(element.date, element.time))

            });
            // Calculate minDate and maxDate from the available slots
            setMinDate(minDate);
            setMaxDate(maxDate);
            setSlots(fetchedSlots);
        })
        return loadingIcon;
    };

    const handleDateChange = (date) => {
        if (value != null)
            setValue({date: date, time: value.time})
        else
            setValue({date: date, time: null}) 
        setSelectedDate(date);
    };

    const handleTimeChange = (event) => {
        if (value != null)
            setValue({date: value.date, time: event})
        else
            setValue({date: null, time: time})
        setSelectedTime(event);
    };

    const availableTimes = (selectedDate !== null) ? slots[Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate()).toString()] || [] : [];

    if (selectedDate != null) {
        console.log("Date: " + Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate()).toString());
        console.log("Times: " + slots[Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate()).toString()])
    }

    const shouldDisableTime = (timeValue) => {
        const timeStr = Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), timeValue.getUTCHours(), timeValue.getUTCMinutes());
        let thisTime;
        if (value !== null && value.time)
            thisTime =Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(),  value.time.getUTCHours(),  value.time.getUTCMinutes());
        return !availableTimes.includes(timeStr) && timeStr !== thisTime ; // Disable times not in available times
    };

    if(value) {
    if (value !== null && selectedDate === null && selectedTime === null) {
        setSelectedDate(value.date);
        setSelectedTime(value.time);
    }}

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr} >

            <DatePicker
                label="Date"
                value={selectedDate}
                onChange={handleDateChange}
                format="dd/MM/yyyy"
                minDate={minDate}
                maxDate={maxDate}
                shouldDisableDate={(date) =>  {
                    return !Object.keys(slots).includes(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()).toString())
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <div style={{marginTop: "15px"}}></div>
            <TimePicker
                label="Heure"
                referenceDate={selectedDate}
                value={selectedTime}
                onChange={handleTimeChange}
                shouldDisableTime={shouldDisableTime}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
