import React from "react";
import { IconButton } from '@mui/material';

import { MTLB_STATUS_ARCHIVED, MTLB_STATUS_CASE_OPENED, MTLB_STATUS_CLOSED, MTLB_STATUS_PV_SUBMITTED, MTLB_STATUS_WAIT_FOR_PV } from "../../../auto/js/metadata/MtlbStatus";
import EditIcon from '@mui/icons-material/Edit';
import { rest, t } from "../../../auto/js/services";
import { displayReadPendingRegistrePoursuiteForm, update } from "../../../auto/js/forms/registrePoursuite/PendingRegistrePoursuiteForm";
import { displayRegistrePoursuiteList } from "../../../auto/js/lists/registrePoursuite/RegistrePoursuiteList";
import { displayReadReadyRegistrePoursuiteForm } from "../../../auto/js/forms/registrePoursuite/ReadyRegistrePoursuiteForm";
import { displayReadApprovedRegistrePoursuiteForm } from "../../../auto/js/forms/registrePoursuite/ApprovedRegistrePoursuiteForm";
import { dto2form, registrePoursuiteFields } from "../forms/registrePoursuite/RegistrePoursuiteFormCommon";
import { whoami } from "../../../auto/js/users/UserInfo";
import { CLOSE_SIDE_PANEL, OPEN_SIDE_PANEL } from "../../../auto/js/events/Gui";
import { createFormComponent } from "../../../auto/js/widgets";

export const RegistrePoursuiteSummary = ({...props}) => {
    return(
      <RegistrePoursuite obj={props.obj} columns={['id', 'legacyId', 'caseStatus']} footerElements= {['created', 'lastModified']} title={"Entrée dans le registre de poursuite"} gui={props.gui}/>
      )
}

class RegistrePoursuite extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: props.obj.data,
        loading: true,
        loadingMessage: ''
      }
      this.updateData = this.updateData.bind(this);
      props.obj.observable.subscribe(this.updateData)
    }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            items.push(
                <div class={colClass}>
                    <div class="results">
                        <h4>{t(element)}</h4>
                        {this.state.data[element]}
                    </div>
                </div>
            )
        });
        return items;
    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    updateData = (data) => {
        this.setState({data: data})
    }

    loadFormData = async () => {
        let form = dto2form(this.state.data);
        let personFilter = {and: true};
		personFilter.victimes = {folderId: this.state.data.folderId};
		return rest.search('victimes-records', personFilter).then(victimes => {
			let v = {};
			if (victimes.length)
				victimes.forEach(element => {
                    let lastname = (element.lastname === null)?'':element.lastname;
                    let othernames = (element.othernames === null)?'':element.othernames + ' ';
                    let firstname = (element.firstname === null)?'':element.firstname + ' ';
					 v[element.id] =  firstname  + othernames  + lastname 
				});
			form.victimes = v;
            let mFilter = {and: true};
            mFilter['misCause'] = {folderId: this.state.data.folderId};
			return rest.search('mis-causes-records', mFilter).then(causes => {
				let m = {};
				if (causes.length)
					causes.forEach(element => {
						let lastname = (element.lastname === null)?'':element.lastname;
                        let othernames = (element.othernames === null)?'':element.othernames + ' ';
                        let firstname = (element.firstname === null)?'':element.firstname + ' ';
                        m[element.id] =  firstname  + othernames  + lastname  
                    });
				form['misCauses'] = m;
                let pFilter = {and: true};
                pFilter['plaignants'] = {folderId: this.state.data.folderId};
                return rest.search('plaignants-records', pFilter).then(plaignants => {
                    let p = {};
                    if (plaignants.length)
                        plaignants.forEach(element => {
                            let lastname = (element.lastname === null)?'':element.lastname;
                            let othernames = (element.othernames === null)?'':element.othernames + ' ';
                            let firstname = (element.firstname === null)?'':element.firstname + ' ';
                            p[element.id] = firstname  + othernames  + lastname 
                        });
                    form.plaignants = p;
				    return form
                })
			})
		})
    }

    form2persist = (data) => {
        if (data.complaintTime != null && typeof(data.complaintTime) != 'string') {
            const date = new Date(data.complaintTime)
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            data.complaintTime = [hour, minute]
        }
        if(data.complaintDate != null && data.complaintDate instanceof Date)
			data.complaintDate = [data.complaintDate.getFullYear(), + ('0' + (data.complaintDate.getMonth() + 1)).slice(-2), ('0' + data.complaintDate.getDate()).slice(-2)];
        return data;
    }

    openSidePanel = (id, readOnly) => {
        let Form = createFormComponent(registrePoursuiteFields(whoami()));
        OPEN_SIDE_PANEL.publish(
            { body: 
                <Form loadData={async () => this.loadFormData(id)} readOnly={readOnly} id={id} buttons={(readOnly)?()=>null:() => this.getButtons()} onSubmit={(data) => update(data).then(() => {
                    CLOSE_SIDE_PANEL.publish()
                    this.props.obj.persist(this.form2persist(data));
                })} />    
            , title: t`Entrée dans le registre de poursuite` }
        )
    }

    openEdit = () => {
        switch(this.state.data.caseStatus) {
            case (MTLB_STATUS_WAIT_FOR_PV):
                this.openSidePanel(this.state.data.id)
                break;
           case (MTLB_STATUS_PV_SUBMITTED):
                this.openSidePanel(this.state.data.id, true)
                break;
           case (MTLB_STATUS_CASE_OPENED):
                this.openSidePanel(this.state.data.id, true)
                break;
           case (MTLB_STATUS_CLOSED):
            this.openSidePanel(this.state.data.id, true)
                break;
           case (MTLB_STATUS_ARCHIVED):
            this.openSidePanel(this.state.data.id, true)
                break;
       }
    }

    getButtons = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <button style={{ minWidth: '5rem' }} className={'reject-button'} type="submit">
                            {t`Submit`}
                        </button>
                    </div>
                </div>
            </>
        )
    }
    
    render() {
        return (
            <>

            {(this.state.data)?
            <div className="rv5info" style={{margin: 'auto', marginBottom: '15px'}}>
            <div class="row">
            <div class="results col-md-11" style={{paddingLeft:"2rem"}}><h2>{t(this.props.title)}</h2></div>
            {(!this.props.readOnly) && <div class="col-md-1">
            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                <EditIcon />
            </IconButton>
            </div>}
            </div>    
            <div class="row" style={{paddingLeft:"1rem", marginLeft: "0px"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>
            </div>:
            <div className="ValidationError">{t(this.props.title) + ' ' + t`not found`}</div>
            }
            </>
        )    
    }
}