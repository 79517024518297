import React, { useState } from "react";
import { useField } from "formik";
import { FormLabel } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay-ts';
import { rest, t } from "../../../auto/js/services";
import { ImageViewer, buildEmptyObject, createFormComponent } from "../../../auto/js/widgets";
import { formatDate } from "../../../auto/js/utils";
import { geoDataMetadataLoader } from "../../../auto/js/metadata/GeoDataMetadataLoader";
import { Button } from "@material-ui/core";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { getServiceUri, pojoMetadata } from "../../../auto/js/metadata";
import { createTableComponent } from "../../../auto/js/widgets/TableComponent";
import { birthRecordColumns, parentsSearchFormfields, transformFormData, substitutFormfields, substitutRecordColumns, transformSubstitutFormData } from "../forms/CivilRecordFormCommon";
import { DraggableFilterDialog } from "./DraggableFilterComponentUpgrade";
import { formState } from "../../../auto/js/forms/FormState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointLeft } from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/pro-regular-svg-icons";
import { loadSubstitutData } from "../utils";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}
function PaperListComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-list"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}

export const AdvancedSearchOnePersonComponent = ({...props}) => {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [searchResult, setSearchResult] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [open, setOpen] = useState(false);
    const [listOpen, setListOpen] = useState(false);
    const [formData, setFormData] = useState(null);
    const [transformedData, setTransformedData] = useState(null);

  const onRequestSearchWithId = (id) => {
        setValue(id);
        setSearchResult(true);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleListClose = () => {
    setListOpen(false);
  };
  const submit = (formData) => {
    const columns = transformSubstitutFormData(formData);
    setFormData(formData);
    setTransformedData(columns);
    setListOpen(true);
    return columns ;
  }

    const openDraggableComponent = () => {
      setOpen(true);
      setListOpen(true);
    }

    const getButtons = () => {
      return <button style={{ minWidth: '5rem', position: 'fixed', right: '20px', top: '5px' }} type="submit"> {t`Find`} </button>
    }

    const buildFormData = async () => {
      return formData;
    }
    let SearchForm = createFormComponent(substitutFormfields);
    let CivilRecordsList = createTableComponent(substitutRecordColumns);

  const getVitalRecordFromSearch = async (filter) => {
     return await rest.create("custom/get-users-by-role", { roleName: "SUBSTITUT_DE_PROCUREUR" });
  }

  const countData = async (filter) => {
     return filter.length;
  }

    const filterData = (DefaultRows, token) => {
      const newRows = [];
      for (let i in DefaultRows) {
        let faceUrl = null;
        let row = DefaultRows[i];
        if (row.faceId != null) {
          faceUrl = getServiceUri() + "face/image/" + row.faceId + "/" + token;
        }
        row.image = (faceUrl != null)?faceUrl:"/public/avatar.png";
        let date = row.birthDate
        if (date !== null)
            row.birthDate = date[2] + '-' + date[1] + '-' + date[0];
        newRows.push(row);
      }
      return newRows;
    }

    const buildData = async (query) => {
      const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
      let filter = query;
      let data;
      filter["substitut-de-procureur-document"] = {inactiveList: [false]};
      filter["orderBy"] = "NAME";
      filter.orderDirection = null;
      filter["filter"] = query.search;
      filter["query"] = transformedData;
      filter.offset = query.page * query.pageSize;
      return await getVitalRecordFromSearch(filter).then(response => {
          data = filterData(response, token)
          return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
      });
    }

    const getTableActions = () => {
      let actions = [];
      actions.push(
        {
          icon: () => <FontAwesomeIcon icon={faHandPointLeft}/>,
            tooltip: t`Choose`,
            onClick: (event, rowData) => {
              onRequestSearchWithId(rowData.id);
              setListOpen(false);
              setOpen(false);
            }
        }
      )
      return actions;
    }


    return(
        <>

        {(!props.readOnly) &&
        <>
        <FormLabel onClick={() => openDraggableComponent()} style={{textDecoration: 'underline', cursor: 'pointer'}} >
        {(props.label)?t(props.label):t(props.name)}
        <FontAwesomeIcon icon={faMagnifyingGlass} onClick={() => openDraggableComponent()} style={{ fontSize: '1.1em', marginLeft: '10px' }}/>
        </FormLabel>
        </>}
      {(searchResult && value)?
      <Person loadData={ async () => props.loadData(value)} disabledPhoto={props.disabledPhoto}/>
      :null}
      {/* {open && <DraggableFilterDialog open={open} onClose={handleClose} title={"Filter your search"} paperComponent={PaperComponent}
      description={""} children={
        <SearchForm loadData={
            formData
              ? async () => buildFormData()
              : async () => {
                if (props.buildEmptyObject !== undefined) {
                  return props.buildEmptyObject(parentsSearchFormfields);
                } else {
                  return buildEmptyObject(parentsSearchFormfields);
                }
              }
          } buttons={getButtons} onSubmit={submit} />
      }/>} */}
      {listOpen && <DraggableFilterDialog open={listOpen} onClose={handleListClose} title={"list"} paperComponent={PaperListComponent} description={"You can choose the user you want to export"} children={<CivilRecordsList loadData={ async (query) => loadSubstitutData(query)} columns={substitutRecordColumns} actions={getTableActions()}  />} />}
          </>
      )
}

class Person extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: undefined,
        loading: true,
        loadingMessage: ''
      }
      this.props.loadData().then((data) => {
        //formState.setState(data);
        this.setState({ data: data, loading: false })
      });
	  }

    componentDidUpdate(prevProps) {
      this.props.loadData().then((data) => {
        //formState.setState(data);
        if (data.id !== this.state.data.id)
          this.setState({ data: data, loading: false })
      });
    }

    openPersonTab = (id) => {
        var currentUrl = window.location.href;
        var url;
        if (currentUrl.includes("#")) {
            var parts = currentUrl.split("#");
            url = parts[0] + '#' + 'viewvr:id=' + id;
        } 
        else
            url = window.location.hash + '#' + 'viewvr:id=' + id;
        window.open(url, '_blank');
    }
    
    render() {
        let fullname = '';
        let birthdate;
        let id;
        let mail;
        let phone;
        if (this.state.data) {
            fullname += this.state.data.firstName ? this.state.data.firstName + ' ' : '';
            fullname += this.state.data.lastName ? this.state.data.lastName + ' ' : '';
            id = this.state.data.id ? this.state.data.id : t`Missing`;
            mail = this.state.data.email ? this.state.data.email : "";
        }
        return (
            <>
            <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.loadingMessage}
            >
            {(this.state.data)?
            <div class="row rv5info">
            {/* (!this.props.disabledPhoto) &&
            <div class="col-md-2">
                <ImageViewer image={this.state.data.image} width={125} height={125}/>  
            </div> */}
             <div class="col-md-4"><div class="results" style={{paddingLeft:"0px"}}><h4>{t`Personal Details`}</h4>
                 <p>{fullname}</p>
             </div>
             </div>
            
             <div class="col-md-3">
                <div class="results">
                  <h4>{t`Contact Details`}</h4>
                 <p>{t`Mail`}: {mail}</p>
                </div>
            </div>
            </div>:
            <div className="ValidationError">{t`Person not found`}</div>
            }
            </LoadingOverlay>
            </>
        )    
    }
}