import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';


import { grey } from "@mui/material/colors";
import { displayReadSoitTransmisForm } from "../../../../auto/js/forms/soitTransmis/NewSoitTransmisForm";
import { displaySoitTransmisList } from "../../../../auto/js/lists/soitTransmis/SoitTransmisList";
import { displayReadApprovedSoitTransmisForm } from "../../../../auto/js/forms/soitTransmis/ApprovedSoitTransmisForm";

export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                    rowData.gui.goTo(displayReadApprovedSoitTransmisForm(), rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}