import * as React from 'react';
import { useField } from "formik";

import { geoDataMetadataLoader } from '../../../auto/js/metadata/GeoDataMetadataLoader';
import { SimpleAutoCompleteInput } from '../../../auto/js/widgets/SimpleAutoCompleteInput';
import { t } from '../../../auto/js/services';

export function CountryAutoCompleteInput(props) {
	const [field, meta, helpers] = useField(props.name);
	const { value } = meta;
	const { setValue } = helpers;
	
    let countryValue;

	const handleChange = (value) => {
		if (props.handleChange)
			props.handleChange(value);
		setValue(geoDataMetadataLoader.getAreaId(value.key));
	};

    if(props.default && value === null) {
        let country = geoDataMetadataLoader.getArea(props.default);
        countryValue = { key: country.id, value: country.name };
        setValue(props.default);
    }

    if (value != null) {
        let country = geoDataMetadataLoader.getArea(value);
        countryValue = { key: country.id, value: country.name };
    }

	return (
        <SimpleAutoCompleteInput layout={""} name={"country"} options={() => geoDataMetadataLoader.getRootNodes()} label={t(props.name) } handleChange={(data) => handleChange(data)}  defaultValue={countryValue} disabled={props.disabled}/>
	);
}