
import {displayNewTranslationsForm} from '../../../auto/js/forms/metadata/TranslationsForm'

import {displayTranslationsList} from '../../../auto/js/lists/metadata/TranslationsList'

import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';

import { displayNewComplaintMtlbForm } from '../../../auto/js/forms/complaintMtlb/NewComplaintMtlbForm'
import { displayComplaintMtlbList } from '../../../auto/js/lists/complaintMtlb/ComplaintMtlbList'
import { displayRegionList } from '../../../auto/js/lists/metadata/RegionList';
import { displayNewRegionForm } from '../../../auto/js/forms/metadata/RegionForm';
import { displayDistrictList } from '../../../auto/js/lists/metadata/DistrictList';
import { displayNewDistrictForm } from '../../../auto/js/forms/metadata/DistrictForm';
import { displayRegistrePoursuiteList } from '../../../auto/js/lists/registrePoursuite/RegistrePoursuiteList';
import { displayAudienceList } from '../../../auto/js/lists/audience/AudienceList';
import { displayJugementList } from '../../../auto/js/lists/jugement/JugementList';
import { displayRegistreAffectationList } from '../../../auto/js/lists/registreAffectation/RegistreAffectationList';
import { displayRegistreOppositionList } from '../../../auto/js/lists/registreOpposition/RegistreOppositionList';
import { displayRegistreAppelList } from '../../../auto/js/lists/registreAppel/RegistreAppelList';
import { displayNewRegistrePoursuiteForm } from '../../../auto/js/forms/registrePoursuite/NewRegistrePoursuiteForm';
import { displayNewRecordsForm } from '../../../auto/js/forms/records/NewRecordsForm';
import { displayRecordsList } from '../../../auto/js/lists/records/RecordsList';
import { displaySoitTransmisList } from '../../../auto/js/lists/soitTransmis/SoitTransmisList';
import { displayNewSoitTransmisForm } from '../../../auto/js/forms/soitTransmis/NewSoitTransmisForm';
import { displayNewMandatDepotForm } from '../../../auto/js/forms/mandatDepot/NewMandatDepotForm';
import { displayMandatDepotList } from '../../../auto/js/lists/mandatDepot/MandatDepotList';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('OPJ') || whoami().roles.includes('GREFFIER_PENAL_BO') || whoami().roles.includes('GREFFIER_ADMIN')) && {
			"folders": {
				submenu: {
					...(whoami().roles.includes('ALL') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_PENAL_BO') || whoami().roles.includes('GREFFIER_ADMIN')) && {
					"ra": {
						options: {
							...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_PENAL_BO')) &&{ new: { label: "Nouveau", do: (gui) => displayNewComplaintMtlbForm(gui) }},
							applications: { label: "Entrés", do: (gui) => displayComplaintMtlbList(gui)}
						},
						label: "Registre d'arrivé"
					}},
                    "rp": {
						options: {
//							...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR')) &&{ new: { label: "Nouveau", do: (gui) => displayNewRegistrePoursuiteForm(gui) }},
							applications: { label: "Entrés", do: (gui) => displayRegistrePoursuiteList(gui)}
						},
						label: "Registre de poursuite"
					},
                    ...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN')) && {
                    "raf": {
						options: {
							applications: { label: "Entrés", do: (gui) => displayRegistreAffectationList(gui)}
						},
						label: "Registre d'affectation"
					}},
					...(whoami().roles.includes('ALL') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN')) && {
						"st": {
							options: {
								applications: { label: "Entrés", do: (gui) => displaySoitTransmisList(gui)}
							},
							label: "Soit transmis"
						}}
				},
				label: "Dossiers"
			}
        }),
		...((whoami().roles.includes('ALL')|| whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_PRISON') || whoami().roles.includes('GREFFIER_CHEF_PRISON') || whoami().roles.includes('GREFFIER_ADMIN')) && {
			"prison": {
				submenu: {
                    "md": {
						options: {
							...((whoami().roles.includes('ALL')|| whoami().roles.includes('SUBSTITUT_DE_PROCUREUR') || whoami().roles.includes('PROCUREUR') || whoami().roles.includes('GREFFIER_ADMIN')) && {
							new: { label: "Nouveau", do: (gui) => displayNewMandatDepotForm(gui) },
							}),
							applications: { label: "Mandat de dépots", do: (gui) => displayMandatDepotList(gui)}
						},
						label: "Mandat de dépots"
					},
				},
				label: "Prison"
			}
        }),        
        ...((whoami().roles.includes('ALL') || whoami().roles.includes('MAINTENANCE')) && {
            "crvs-admin": {
                submenu: {

                    "translations": {
                        options: {
                            list: {label: "Translations", do: displayTranslationsList},
                            new: {label: "New", do: displayNewTranslationsForm},
                        },
                        label: "Translations"
                    },
                    "region": {
                        options: {
                            list: {label: "Regions", do: (gui) => displayRegionList(gui)},
                            new: {label: "New", do: (gui) => displayNewRegionForm(gui)},
                        },
                        label: "Region"
                    },
                    "distric": {
                        options: {
                            list: {label: "Districts", do: (gui) => displayDistrictList(gui)},
                            new: {label: "New", do: (gui) => displayNewDistrictForm(gui)},
                        },
                        label: "District"
                    },
                },
                label: "Configuration"
            }
        })
    }
}
export const menuType = RIBBON_MENU;
