import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateMandatDepotForm, loadMandatDepotFormData } from "./MandatDepotApi";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";
import { Section } from "../../../../auto/js/widgets/Section";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadFormData, mandatDepotFields } from "./NewMandatDepotForm";
import TemplateComponent from "react-mustache-template-component";
import { geoDataMetadataLoader } from "../../metadata/GeoDataMetadataLoader";

	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		let MandatDepotForm = createFormComponent(mandatDepotFields);
		return (
		<>
			<MandatDepotForm key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => getButtons(this.props.id)} />
		</>
		)
	}

}

	

export const displayReadApprovedMandatDepotForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} />
	};
}

const getButtons = (id) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
			<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => printMandatDepot(id)}> {t`Imprimer`} </button>
		</div>
	</div>
    </>
    )
}

export const printMandatDepot = (id) => {
	rest.read('forms/mandat-de-depot', id).then(async md => {
		printTemplate(md)
	})
}

const printTemplate = (data) => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={dto2form(data.templateData)} />,
		copyParentStryle: false
	})
}

const dto2form = (dto) => {
	let form = dto; 
	if (dto.birthPlace != null)
		form.birthPlace = formatLocation(dto.birthPlace);
	if (dto.citizenship != null)
		form.citizenship = formatLocation(dto.citizenship);
	if (dto.address != null)
		form.address = formatLocation(dto.address);
	if (dto.birthdate != null)
		form.birthdate = formatDate(dto.birthdate);
	if (dto.flagrantDelits != null)
		form.flagrantDelits = formatDate(dto.flagrantDelits);
	if (dto.citationDirect != null)
		form.citationDirect = formatDate(dto.citationDirect);
	var currentdate = new Date();
	form.nowDate = currentdate.getDate() + "/"
	+ (currentdate.getMonth() + 1) + "/"
	+ currentdate.getFullYear();
	return form;
}

const formatDate = (date) => {
	let d = (date[2] >= 10)?date[2]:"0"+date[2];
	let m = (date[1] >= 10)?date[1]:"0"+date[1];
	return d + "/" + m + "/" + date[0];
}

const formatLocation = (location) => {
	let birthPlace = [];
	if (location != null) {
		let birthPlaceComponents = location.split(".");
		let place = location;
		birthPlace.push(
			(geoDataMetadataLoader.getArea(place))?t(geoDataMetadataLoader.getArea(place).name + " "):t("INVALID DATA ")
		)
		for (let i = 0; i < birthPlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			birthPlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?t(geoDataMetadataLoader.getArea(parentAreaId).name + " "):t("INVALID DATA ")
			)                
		}
	}
	return birthPlace;
}
