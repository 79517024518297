import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { COMPLAINT_MTLB_ORDER_BY_ID, COMPLAINT_MTLB_ORDER_BY_SENDER_FIRST_NAME } from "../../../../auto/js/metadata/ComplaintMtlbOrderBy";
import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { rest, t } from "../../../../auto/js/services";

export const registrePoursuiteListColumns = [
    { title: "Case ID", field: "folderId" },
    { title: "Plaignants", field: "plaignants" },
    { title: "Mis en Causes", field: "misCause" },
    { title: "Victimes", field: "victimes" },
    { title: "pvId", field: "pvId" },
    { title: "soitTransmisId", field: "soitTransmisId" },
    { title: "Registration Date", field: "complaintDate" },
    {title: "Status", field: "status" }
];

export const transformAdvancedSearchData = (formData) => {
    const columns = [];

    return { columns };
};

export const advancedSearchfields = [
    
];

export const getData = async (filter) => {
    let searchEndpoint = (filter.query) ? "registre-poursuite/advanced-search" : "registre-poursuite/search-document/";
    return await rest.request(getServiceUri() + searchEndpoint, "POST", filter);
}

export const countData = async (filter) => {
    let countEndpoint = (filter.query) ? "registre-poursuite/count/advanced-search" : "registre-poursuite/count-document/";
    return await rest.request(getServiceUri() + countEndpoint, "POST", filter);
}

export const getOrderBy = () => {
    return COMPLAINT_MTLB_ORDER_BY_ID
}

export const filterData = async (defaultRows) => {
    for (let i in defaultRows) {
        let row = defaultRows[i];
        if (row.caseStatus != null)
            row.status = t(row.caseStatus);
        let date = row.complaintDate
        if ( date !== null)
            row.complaintDate = date[2] + '-' + date[1] + '-' + date[0];

        let plaignants = '';
            let personFilter = {and: true};
            personFilter['plaignants'] = {folderId: row.folderId};
            await rest.search('plaignants-records', personFilter).then(p => {
                if (p.length) {
                    let first = true;
                    p.forEach(element => {
                        if (first) {
                            plaignants = element.firstname;
                            first = false;
                        } 
                        else {
                            plaignants = plaignants.concat(', ', element.firstname)
                        }
                        if (element.lastname != null) 
                            plaignants = plaignants.concat(' ', element.lastname)
                    });
                }
                
            })
            row.plaignants = plaignants;
    
            let victimes = '';
            let vFilter = {and: true};
            vFilter['victimes'] = {folderId: row.folderId};
            await rest.search('victimes-records', vFilter).then(p => {
                if (p.length) {
                    let first = true;
                    p.forEach(element => {
                        if (first) {
                            victimes = element.firstname;
                            first = false;
                        } 
                        else {
                            victimes = victimes.concat(', ', element.firstname)
                        }
                        if (element.lastname != null) 
                            victimes = victimes.concat(' ', element.lastname)
                    });
                }
                
            })
            row.victimes = victimes;
    
            let misCause = '';
            let mFilter = {and: true};
            mFilter['misCause'] = {folderId: row.folderId};
            await rest.search('mis-causes-records', mFilter).then(p => {
                if (p.length) {
                    let first = true;
                    p.forEach(element => {
                        if (first) {
                            misCause = element.firstname;
                            first = false;
                        } 
                        else {
                            misCause = misCause.concat(', ', element.firstname)
                        }
                        if (element.lastname != null) 
                            misCause = misCause.concat(' ', element.lastname)
                    });
                }
                
            })
            row.misCause = misCause;
    }
    return defaultRows;
}

export const getErrorList = () => {
    let list = [];
    
    return list;
}

