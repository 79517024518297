import 'date-fns';
import React from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import LoadingOverlay from 'react-loading-overlay-ts';
import * as Yup from 'yup';


 
import "react-datepicker/dist/react-datepicker.css";
import "../pages/Form.css";
 
import { EventComponent } from './EventComponent';
import { t } from '../../../auto/js/services';
import { RegistreArriveeSummary } from './RegistreArriveeSummary';
import { RegistreAffectationSummary } from './RegistreAffecationSummary';
import { RegistrePoursuiteSummary } from './RegistrePoursuiteSummary';
import { SoitTransmisSummary } from './SoitTransmisSummary';
import { memstore } from '../Memstore';
import { JugementSummary } from './JugementSummary';
import { RegistreOppositionSummary } from './RegistreOppositionSummary';
import { RegistreAppelSummary } from './RegistreAppelSummary';
import { AudienceSummary } from './AudienceSummary';
import { MandatDepotSummary } from './MandatDepotSummary';
import { MandatAmenerSummary } from './MandatAmenerSummary';
 
const INFO_TAG_TYPE = "INFO";
const WARNING_TAG_TYPE = "WARNING";
const ERROR_TAG_TYPE = "ERROR";
 

const splitFieldsByRow = (fields) => {
  var rows = [];
  fields.forEach(field => {
    if (!rows[field.y])
      rows[field.y] = [];
    rows[field.y].push(field);
  });
  return rows;
}
 
const sortFieldsByX = (fields) => {
  fields.sort((a, b) => a.x - b.x);
  return fields;
}

const buildRules = (fields) => {
    let rule = {};
    fields.forEach(element => {
        if(element.validation)
        rule[element.name] = element.validation;
        if (element.type === "box") {
        element.components.forEach(e => {
            if(e.validation)
            rule[ e.name] = e.validation;
        });
        }
    });
    return rule
}
 
 
const buildRows = (fields, data, readOnly, gui) => {
  const items = [];
  JSON.parse(data.data).documents.forEach(element => {
    switch(element.documentType) {
      case ("REGISTRE_ARRIVEE"):
        let raObj = memstore.save(element.complaintMtlb);
        items.push(
          <div className={'col-md-12'}>
              <RegistreArriveeSummary gui={gui} obj={raObj} />
          </div>
        )
        break;
      case ("MANDAT_AMENER"):
        let maObj = memstore.save(element.mandatAmener);
        items.push(
          <div className={'col-md-12'}>
              <MandatAmenerSummary gui={gui} obj={maObj}/>
          </div>
        )
        break;
      case ("REGISTRE_POURSUITE"): 
        let rpObj = memstore.save(element.registrePoursuite);
        items.push(
          <div className={'col-md-12'}>
              <RegistrePoursuiteSummary gui={gui} obj={rpObj}/>
          </div>
        )
        break;
      case ("SOIT_TRANSMIS"):
        let stObj = memstore.save(element.soitTransmis);
        items.push(
          <div className={'col-md-12'}>
              <SoitTransmisSummary gui={gui} obj={stObj}/>
          </div>
        )
        break;
        case ("MANDAT_DEPOT"):
        let mdObj = memstore.save(element.mandatDepot);
        items.push(
          <div className={'col-md-12'}>
              <MandatDepotSummary gui={gui} obj={mdObj}/>
          </div>
        )
        break;
      case ("JUGEMENT"):
        if (element.jugement.date) {
          let jObj = memstore.save(element.jugement);
          items.push(
            <div className={'col-md-12'}>
                <JugementSummary gui={gui} obj={jObj}/>
            </div>
          )
        }
        break;
      case ("REGISTRE_OPPOSITION"):
        let roObj = memstore.save(element.registreOpposition);
        items.push(
          <div className={'col-md-12'}>
              <RegistreOppositionSummary gui={gui} obj={roObj}/>
          </div>
        )
        break;
      case ("REGISTRE_APPEL"):
        let rappelObj = memstore.save(element.registreAppel);
        items.push(
          <div className={'col-md-12'}>
              <RegistreAppelSummary gui={gui} obj={rappelObj}/>
          </div>
        )
        break;
      case ("AUDIENCE"):
        let audienceObj = memstore.save(element);
        items.push(
          <div className={'col-md-12'}>
              <AudienceSummary gui={gui} obj={audienceObj}/>
          </div>
        )
        break;
      /* default:
        items.push(
          <div className={'col-md-12'}>
              <h2>{element.documentType}</h2>
          </div>
        ) */
    }
 });
  return items;
};
 
export const createTimelineWidgetsComponent = (fields) => class TimelineWidgetsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef()
    this.state = {
      data: undefined,
      refreshSeq: 0,
      loading: true,
      loadingMessage: ''
    }
    props.loadData().then((initialValues) => this.setState({ data: initialValues, loading: false }));
    this.validationSchema = Yup.object().shape(Object.assign({}, buildRules(fields)));

  }
 
  refresh = () => {
    setState({ refreshSeq: refreshSeq + 1 });
  }

 
  onKeyDown = (event) => {
    if (event.keyCode === 13)
      event.preventDefault();
  }
 
  render() {
    return (
 
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingMessage}
      >
        { this.state.data && (   
        <div>
            { buildRows(fields, this.state.data, this.props.readOnly, this.props.gui) }
        </div>
        )}
      </LoadingOverlay>
    );
  }
}