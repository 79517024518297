// DateTimePicker.js
import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { fr } from 'date-fns/locale';
import { rest } from '../../../auto/js/services';
import { getServiceUri } from '../../../auto/js/metadata';
import { loadingIcon } from '../../../auto/js/loadingIcon';
import { useField } from 'formik';

const formatDate = (date) => {
    let year = date[0];
    let month = (date[1] < 10)?"0" + date[1]:date[1];
    let day = (date[2] < 10)?"0" + date[2]:date[2];
    return day + '-' + month + '-' + year;
}

const formatTime = (time) => {
    let h = (time[0] < 10)?"0" + time[0]:time[0];
    let m = (time[1] < 10)?"0" + time[1]:time[1];
    return h + ':' + m;
}

const DateTimePicker = (props) => {

    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const [slots, setSlots] = useState(undefined);
    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);

    if(!slots) {
        rest.request(getServiceUri() + 'custom/get-available-audience', "GET").then((response) => {
            let fetchedSlots = {}
            let maxDate = new Date();
            let minDate = new Date();
            response.forEach(element => {
                if (!fetchedSlots[formatDate(element.date)]) {
                    fetchedSlots[formatDate(element.date)] = [formatTime(element.time)];
                    let date = new Date(formatDate(element.date));
                    if (date > maxDate)
                        maxDate = date;
                    if (date < minDate)
                        minDate = date;
                }
                else
                    fetchedSlots[formatDate(element.date)].push(formatTime(element.time))

            });
            // Calculate minDate and maxDate from the available slots
            setMinDate(minDate);
            setMaxDate(maxDate);
            setSlots(fetchedSlots);
        })
        return loadingIcon;
    };

    const handleDateChange = (date) => {
        if (value != null)
            setValue({date: date, time: value.time})
        else
            setValue({date: date, time: null}) 
        setSelectedDate(date);
    };

    const handleTimeChange = (event) => {
        if (value != null)
            setValue({date: value.date, time: event})
        else
            setValue({date: null, time: time})
        setSelectedTime(event);
    };

    const availableTimes = (selectedDate !== null) ? slots[selectedDate.toLocaleString().split(' ')[0].replaceAll("/", "-")] || [] : [];

    if (selectedDate != null) {
        console.log("Date: " + selectedDate.toLocaleString().split(' ')[0].replaceAll("/", "-"));
        console.log("Times: " + slots[selectedDate.toLocaleString().split(' ')[0].replaceAll("/", "-")])
    }

    const shouldDisableTime = (timeValue) => {
        const timeStr = timeValue.toTimeString().slice(0, 5); // Get "HH:mm" format
        return !availableTimes.includes(timeStr); // Disable times not in available times
    };

    if (value !== null && selectedDate === null && selectedTime === null) {
        setSelectedDate(value.date);
        setSelectedTime(value.time);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr} >

            <DatePicker
                label="Sélectionnez une date"
                value={selectedDate}
                onChange={handleDateChange}
                format="dd/MM/yyyy"
                minDate={minDate}
                maxDate={maxDate}
                shouldDisableDate={(date) => date.getDay() === 0 || date.getDay() === 6} // Exclude weekends
                renderInput={(params) => <TextField {...params} fullWidth />}
            />
            <div style={{marginTop: "15px"}}></div>
            <TimePicker
                label="Sélectionnez une heure"
                value={selectedTime}
                onChange={handleTimeChange}
                shouldDisableTime={shouldDisableTime}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};

export default DateTimePicker;
