import React from "react";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { CountryAutoCompleteInput } from "../../widgets/CountryAutoCompleteInput";

export const customMandatDepotForm2Dto = (formData, dto) => {

}

export const customMandatDepotDto2Form = (dto, form) => {

}

export const birthPlaceComponentHook = (name, disabled) => {
    return <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
}

export const citizenshipComponentHook = (name, disabled) => {
    return <CountryAutoCompleteInput name={name} disabled={disabled} default={"48"}/>
}

export const addressComponentHook = (name, disabled) => {
    return <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
}