import 'date-fns';
import React from "react";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Form, Formik } from "formik";
import LoadingOverlay from 'react-loading-overlay-ts';
 
import { i18ndb, t } from "../services";
import { appContext } from '../AppContext';
import {
  listMetadata
} from "../metadata";
import {
  Select, DateInput, CheckboxComponent, PasswordInput, TextInput, ImageInput
} from '../widgets';
import { ImageViewer } from './ImageViewer';
 
import "react-datepicker/dist/react-datepicker.css";
import "../pages/Form.css";
import { AutoCompleteInput } from './AutoCompleteInput';
import { TimeInput } from './TimeInput';
 
import * as Yup from 'yup';
import { RadioComponent } from './RadioComponent';
import { FileInput } from './FileInput';
 
const INFO_TAG_TYPE = "INFO";
const WARNING_TAG_TYPE = "WARNING";
const ERROR_TAG_TYPE = "ERROR";
 
export const buildEmptyObject = (fields) => {
  const empty = {};
  for (let i = 0; i < fields.length; i++) {
    let field = fields[i];
    switch (field.type) {
      case ("text"):
        empty[field.name] = "";
        break;
      case ("number"):
        empty[field.name] = "";
        break;
      case ("checkbox"):
        empty[field.name] = false;
        break;
      case ("timestampz"):
        empty[field.name] = '';
        break;
      case ("date"):
        empty[field.name] = null;
        break;
      case ("time"):
        empty[field.name] = null;
        break;
      case ("select"): // dynamic lists, loaded from the backend
        empty[field.name] = '';
        break;
      case ("list"): // static lists, hardcoded
        empty[field.name] = '';
        break;
      case ("password"):
        empty[field.name] = '';
        break;
      case ("box"):
        Object.assign(empty, buildEmptyObject(field.components));
        break
    }
  }
 
  return empty;
}
 
const splitFieldsByRow = (fields) => {
  var rows = [];
  fields.forEach(field => {
    if (!rows[field.y])
      rows[field.y] = [];
    rows[field.y].push(field);
  });
  return rows;
}
 
const sortFieldsByX = (fields) => {
  fields.sort((a, b) => a.x - b.x);
  return fields;
}
 
 
const buildRows = (fields, data, touched, errors, readOnly) => {
  var fieldsByRow = splitFieldsByRow(fields)
  const items = [];
 
  fieldsByRow.forEach(row => {
    var sortedFields = sortFieldsByX(row);
    var rowItems = [];
    sortedFields.forEach(field => {
      var display = true;
 
      if(field.display){
          display = field.display(data)
      }
      if(display){
      switch (field.type) {
        case("tags"):
          if (data[field.name] != null) {
            let chips = [];
            data[field.name].forEach(element => {
              let tagColor;
              if(element.type == WARNING_TAG_TYPE)
                tagColor = "warning"
              else if (element.type == ERROR_TAG_TYPE)
                tagColor = "error"
              else if (element.type == INFO_TAG_TYPE)
                tagColor = "success"
              else
                tagColor = "default"
              let tagContent = element.content;
              if (element.key != null)
                tagContent = t(element.key) + ": " + element.content;
              chips.push(          
                <Chip
                  label={t(tagContent)}
                  color={tagColor}
                />
              )
            });
            rowItems.push(
              <div className={field.layout}>
                <Stack direction="row" spacing={1}>
                  {chips}
                </Stack>
              </div>
            )
          }
          break
        case ("text"):
        case ("number"):
          if(field.display){
            if( field.display(data) ){
              rowItems.push(
                <div className={field.layout}>
                  <TextInput
                    label={i18ndb._(field.name)}
                    name={field.name} disabled={(field.disabled)?field.disabled:readOnly}
                  />
                    {errors[field.name] && touched[field.name] ? (
                      <div className="ValidationError">{t(errors[field.name])}</div>) : null}
                </div>
              );
            }
          }else{
            rowItems.push(
              <div className={field.layout}>
                <TextInput
                  label={i18ndb._(field.name)}
                  name={field.name} disabled={(field.disabled)?field.disabled:readOnly}
                />
                  {errors[field.name] && touched[field.name] ? (
                    <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            );
          }
         
          break;
        case ("validation"):
          rowItems.push(
            <div className={field.layout}>
              {errors[field.name] ? (
                <div className="ValidationError">{t(errors[field.name])}</div>) : null}
            </div>
          )
          break;
        case ("custom"):
          rowItems.push(
            <div className={field.layout}>
              {field.component(field.name, (field.disabled)?field.disabled:readOnly, (data)?data:null)}
              {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
            </div>
          )
          break;  
        case ("checkbox"):
          rowItems.push(
            <>
              <div className={field.layout}>
                <CheckboxComponent name={field.name} label={i18ndb._(field.name)} language={appContext.getLanguage()} disabled={(field.disabled)?field.disabled:readOnly} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            </>
          )
          break;
          case ("radio"):
          rowItems.push(
            <>
              <div className={field.layout}>
                <RadioComponent name={field.name} options={field.options} label={i18ndb._(field.name)} defaultValue={field.defaultValue} disabled={(field.disabled)?field.disabled:readOnly} />     
              </div>
            </>
          )
          break;
          case ("box"):
          rowItems.push(
            <>
              <fieldset className={field.layout} style={{marginLeft:"20px", borderRadius: "20px", marginBottom: '20px', border: '1px dashed #5884b3'}}>
                <legend style={{padding: '0.2em 0.5em', border: '1px dashed #5884b3', fontSize: '0.8rem', borderRadius: '10px', width: 'auto', color: '#5884b3', marginBottom: '0px'}}>{i18ndb._(field.label)}</legend>
                {buildRows(field.components, data, touched, errors, readOnly)}
              </fieldset>
            </>
          )
          break;
        case ("timestampz"):
          rowItems.push(
            <>
              <div className={field.layout}>
                <DateInput name={field.name} label={i18ndb._(field.name)} language={appContext.getLanguage()} disabled={(field.disabled)?field.disabled:readOnly} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            </>
          )
          break;
        case ("date"):
          rowItems.push(
            <>
              <div className={field.layout}>
                <DateInput
                  name={field.name} label={i18ndb._(field.name)} language={appContext.getLanguage()} disabled={(field.disabled)?field.disabled:readOnly} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            </>
          )
          break;
        case ("time"):
          rowItems.push(
            <>
              <div className={field.layout}>
                <TimeInput
                  name={field.name} label={i18ndb._(field.name)} disabled={(field.disabled)?field.disabled:readOnly} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            </>
          )
          break;
        case ("select"): // dynamic lists, loaded from the backend
          rowItems.push(
            <>
              <div className={field.layout}>
                <Select name={field.name} label={i18ndb._(field.name)} id={field.name}
                 list={field.metadata()} disabled={(field.disabled)?field.disabled:readOnly}/>
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            </>
          );
          break;
        case ("list"): // static lists, hardcoded
          rowItems.push(
            <>
              <div className={field.layout}>
                <Select name={field.name} label={i18ndb._(field.name)} id={field.name}
                  onChange={handleChange} list={listMetadata[entity][field.name]}
                  disabled={(field.disabled)?field.disabled:readOnly} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
              </div>
            </>
          );
          break;
        case ("password"):
          rowItems.push(
            <div className={field.layout}>
              <PasswordInput
                label={i == 0 ? "" : i18ndb._(field.name)}
                name={field.name}
              />
              {errors[field.name] && touched[field.name] ? (
                <div className="ValidationError">{t(errors[field.name])}</div>) : null}
            </div>
          );
          break;
        case ("image"):
          rowItems.push(
            <div className={field.layout}>
              <ImageInput name={field.name} disabled={(field.disabled)?field.disabled:readOnly} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
            </div>
          );
          break;
        case ("view"):
        rowItems.push(
          <div className={field.layout} style={{textAlign:'center'}}>
            <div className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">{i18ndb._(field.name)}</div>
            <ImageViewer image={data[field.name]} width={data[field.name].width} height={data[field.name].height} />
          </div>
        );
        break;
        case ("autocomplete"):
          rowItems.push(
            <div className={field.layout}>
              <AutoCompleteInput name={field.name} disabled={(field.disabled)?field.disabled:readOnly}
                options={field.metadata()} label={i18ndb._(field.name)} handleChange={field.handleChange} observable={field.observable} />
                {errors[field.name] && touched[field.name] ? (
                  <div className="ValidationError">{t(errors[field.name])}</div>) : null}
            </div>
          );
          break
        case ("file"): 
          rowItems.push(
            <div className={field.layout}>
              <FileInput uploadUrl={field.uploadUrl(data.id)}
              loadData={async () => field.loadData(data.id)}
              previewUrl={(id) => field.previewUrl(id)}
              handleDelete={(id) => field.handleDelete(id)} 
              handleClick={async (id) => field.handleClick(id)}
              updateFileData={field.updateFileData} 
              readOnly={(field.disabled)?field.disabled:readOnly}
              label={field.label}
              />
            </div>
          )
          break
        default:
          rowItems.push(
            <div className={field.layout}>
              <TextInput
                label={i18ndb._(field.name)}
                name={field.name} disabled={(field.disabled)?field.disabled:readOnly}
                type={field.type}
              />
              {errors[field.name] && touched[field.name] ? (
                <div className="ValidationError">{t(errors[field.name])}</div>) : null}
            </div>
          )
      }
    }
    });
 
    items.push(
      <div className="row">{rowItems}</div>
    )
  });
 
  return items;
};

const buildRules = (fields) => {
  let rule = {};
  fields.forEach(element => {
    if(element.validation)
      rule[element.name] = element.validation;
    if (element.type === "box") {
      element.components.forEach(e => {
        if(e.validation)
          rule[ e.name] = e.validation;
      });
    }
  });
  return rule
}
 
export const createFormComponent = (fields) => class FormComponent extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef()
    this.state = {
      data: undefined,
      refreshSeq: 0,
      loading: true,
      loadingMessage: ''
    }
    this.dirty = undefined;
    props.loadData().then((initialValues) => this.setState({ data: initialValues, loading: false }));
    this.validationSchema = Yup.object().shape(Object.assign({}, buildRules(fields)));
  }
 
  refresh = () => {
    setState({ refreshSeq: refreshSeq + 1 });
  }
 
  isDirty = () => {
    return this.dirty
  }
 
  handleDirty = (dirty) => {
    this.dirty = dirty;
  }
 
  save = () => {
    return new Promise((resolve) => {
      this.formRef.current.submitForm().then((response) => {
        resolve(response);
        });
    })
  }
 
  onKeyDown = (event) => {
    if (event.keyCode === 13)
      event.preventDefault();
  }
 
  render() {
    return (
 
      <LoadingOverlay
        active={this.state.loading}
        spinner
        text={this.state.loadingMessage}
      >
        { this.state.data && (
          <Formik
            innerRef={this.formRef}
            enableReinitialize
            initialValues={this.state.data}
            validationSchema={this.validationSchema}
            onSubmit={(values, actions) => {
              this.props.onSubmit(values).then(
                actions.setSubmitting(false),
                this.handleDirty(false)
              );
            }}
          >
            {({errors, touched, dirty, values}) => (
              <Form onKeyDown={this.onKeyDown}>
                {
                  this.props.buttons(values)
                }
                <div>
                  { buildRows(fields, values, touched, errors, this.props.readOnly) }
                </div>
                {
                  this.handleDirty(dirty)
                }
              </Form>
            )}
          </Formik>
        )}
      </LoadingOverlay>
    );
  }
}