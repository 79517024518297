import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/complaintMtlb/ComplaintMtlbFormCommon";

export const saveComplaintMtlbForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['complaint-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'complaint-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadComplaintMtlbFormData = async (id) => {
	return await rest.read('complaint-mtlb', id).then(response => {
		let form = dto2form(response);
        
		let personFilter = {and: true};
		personFilter.victimes = {folderId: response.id};
		return rest.search('victimes-records', personFilter).then(victimes => {
			let v = {};
			if (victimes.length)
				victimes.forEach(element => {
                    let lastname = (element.lastname === null)?'':element.lastname;
                    let othernames = (element.othernames === null)?'':element.othernames + ' ';
                    let firstname = (element.firstname === null)?'':element.firstname + ' ';
					 v[element.id] =  firstname  + othernames  + lastname 
				});
			form.victimes = v;
            let mFilter = {and: true};
            mFilter['misCause'] = {folderId: response.id};
			return rest.search('mis-causes-records', mFilter).then(causes => {
				let m = {};
				if (causes.length)
					causes.forEach(element => {
						let lastname = (element.lastname === null)?'':element.lastname;
                        let othernames = (element.othernames === null)?'':element.othernames + ' ';
                        let firstname = (element.firstname === null)?'':element.firstname + ' ';
                        m[element.id] =  firstname  + othernames  + lastname  
                    });
				form['misCauses'] = m;
                let pFilter = {and: true};
                pFilter['plaignants'] = {folderId: response.id};
                return rest.search('plaignants-records', pFilter).then(plaignants => {
                    let p = {};
                    if (plaignants.length)
                        plaignants.forEach(element => {
                            let lastname = (element.lastname === null)?'':element.lastname;
                            let othernames = (element.othernames === null)?'':element.othernames + ' ';
                            let firstname = (element.firstname === null)?'':element.firstname + ' ';
                            p[element.id] = firstname  + othernames  + lastname 
                        });
                    form.plaignants = p;
				    return form
                })
			})
		})
	})
}

export const updatePendingComplaintMtlbForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['complaint-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateComplaintMtlbForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['complaint-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/edit-document', 'POST', dto);
    } catch (err) {
        alert(err);
    }
}

export const investigateComplaint = async (id) => {
    try {
        return rest.request(getServiceUri() + 'v1/apply/investigate', 'POST', {id:id});
    } catch (err) {
        alert(err);
    }
}

export const classerComplaint = async (id) => {
    try {
        return rest.request(getServiceUri() + 'v1/apply/close-complaint', 'POST', {id:id});
    } catch (err) {
        alert(err);
    }
}

export const deleteComplaintMtlbForm = async (id) => {
    try {
        return rest.delete('complaint-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedComplaintMtlbForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['complaint-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated complaint-mtlb`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectComplaintMtlbForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyComplaintMtlbForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyComplaintMtlbForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'complaint-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}
