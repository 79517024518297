import React from "react";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { pojoMetadata, getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { showNotification, swapObject } from "../../../../auto/js/utils";
import { AlertDialog } from "../../../../auto/js/widgets/Dialogs";
import { createFormComponent } from "../../../../auto/js/widgets/FormComponent";
import { customAudienceForm2Dto, customAudienceDto2form } from "../../../../main/js/forms/audience/AudienceFormCommon.jsx";
import { saveAudienceForm, updateAudience, deleteAudience, loadAudienceData } from "./AudienceApi";
import * as Yup from 'yup';
import DateTimePicker from "../../../../main/js/widgets/DateTimePicker";


const audienceFields = [
    /* {
        name: "date",
        label: "date",
        type: "date",
        x: 1,
        y: 1,
        layout: "col-md-6",
        validation:
                Yup.date()
                    .nullable()
                    .default(undefined)
                    .required(t`date is required`)
    },
    {
        name: "time",
        label: "time",
        type: "time",
        x: 2,
        y: 1,
        layout: "col-md-6",
        validation:
            Yup.string()
                .nullable()
                .default(undefined)
                .required(t`time is required`)
    }, */
    {
        name: "calendar", type: "custom", x: 1, y: 1, layout: "col-md-6", component: (name, disabled) => <DateTimePicker name={name} />

    },
    {
        name: "mandatDAmener",
        label: "mandatDAmener",
        type: "file",
        x: 1,
        y: 2,
        layout: "col-md-12",
        uploadUrl: (id) => getAudienceMandatDAmenerUploadUrl(id),
        previewUrl: (id) => getAudienceMandatDAmenerPreviewUrl(id),
        loadData: async (id) => loadAudienceMandatDAmenerData(id),
        handleDelete:(id) => handleAudienceMandatDAmenerDelete(id),
        handleClick: (id) => handleAudienceMandatDAmenerClick(id),
        updateFileData: (data) => updateAudienceMandatDAmenerData(data),
    },
    {
        name: "convocationDesTemoins",
        label: "convocationDesTemoins",
        type: "file",
        x: 1,
        y: 3,
        layout: "col-md-12",
        uploadUrl: (id) => getAudienceConvocationDesTemoinsUploadUrl(id),
        previewUrl: (id) => getAudienceConvocationDesTemoinsPreviewUrl(id),
        loadData: async (id) => loadAudienceConvocationDesTemoinsData(id),
        handleDelete:(id) => handleAudienceConvocationDesTemoinsDelete(id),
        handleClick: (id) => handleAudienceConvocationDesTemoinsClick(id),
        updateFileData: (data) => updateAudienceConvocationDesTemoinsData(data),
    },
    {
        name: "mandatDArret",
        label: "mandatDArret",
        type: "file",
        x: 1,
        y: 4,
        layout: "col-md-12",
        uploadUrl: (id) => getAudienceMandatDArretUploadUrl(id),
        previewUrl: (id) => getAudienceMandatDArretPreviewUrl(id),
        loadData: async (id) => loadAudienceMandatDArretData(id),
        handleDelete:(id) => handleAudienceMandatDArretDelete(id),
        handleClick: (id) => handleAudienceMandatDArretClick(id),
        updateFileData: (data) => updateAudienceMandatDArretData(data),
    },
    {
        name: "mandatDeDepot",
        label: "mandatDeDepot",
        type: "file",
        x: 1,
        y: 5,
        layout: "col-md-12",
        uploadUrl: (id) => getAudienceMandatDeDepotUploadUrl(id),
        previewUrl: (id) => getAudienceMandatDeDepotPreviewUrl(id),
        loadData: async (id) => loadAudienceMandatDeDepotData(id),
        handleDelete:(id) => handleAudienceMandatDeDepotDelete(id),
        handleClick: (id) => handleAudienceMandatDeDepotClick(id),
        updateFileData: (data) => updateAudienceMandatDeDepotData(data),
    },
    {
        name: "attachments",
        label: "attachments",
        type: "file",
        x: 1,
        y: 6,
        layout: "col-md-12",
        uploadUrl: (id) => getAudienceAttachmentsUploadUrl(id),
        previewUrl: (id) => getAudienceAttachmentsPreviewUrl(id),
        loadData: async (id) => loadAudienceAttachmentsData(id),
        handleDelete:(id) => handleAudienceAttachmentsDelete(id),
        handleClick: (id) => handleAudienceAttachmentsClick(id),
        updateFileData: (data) => updateAudienceAttachmentsData(data),
    }
]

const getAudienceMandatDAmenerUploadUrl = (id) => {
    return getServiceUri() + 'audience/mandat-d-amener' + '/' + id;
};
const handleAudienceMandatDAmenerClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'audience/mandat-d-amener' + '/' + id + '/' + token;
};
const getAudienceMandatDAmenerPreviewUrl = (id) => {
	return getServiceUri() + 'audience/mandat-d-amener' + '/preview/' + id + '/';
};
const updateAudienceMandatDAmenerData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'audience/mandat-d-amener' + '/' + data.id, "PUT", filter);
};
const handleAudienceMandatDAmenerDelete = (id) => {
	rest.delete('audience/mandat-d-amener', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadAudienceMandatDAmenerData = async (id) => {
	let filter = {and: true};
	filter['audience-mandat-d-amener'] = {};
	filter['audience-mandat-d-amener']['audienceId'] = id;
	return rest.search('audience/mandat-d-amener', filter)
}
const getAudienceConvocationDesTemoinsUploadUrl = (id) => {
    return getServiceUri() + 'audience/convocation-des-temoins' + '/' + id;
};
const handleAudienceConvocationDesTemoinsClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'audience/convocation-des-temoins' + '/' + id + '/' + token;
};
const getAudienceConvocationDesTemoinsPreviewUrl = (id) => {
	return getServiceUri() + 'audience/convocation-des-temoins' + '/preview/' + id + '/';
};
const updateAudienceConvocationDesTemoinsData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'audience/convocation-des-temoins' + '/' + data.id, "PUT", filter);
};
const handleAudienceConvocationDesTemoinsDelete = (id) => {
	rest.delete('audience/convocation-des-temoins', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadAudienceConvocationDesTemoinsData = async (id) => {
	let filter = {and: true};
	filter['audience-convocation-des-temoins'] = {};
	filter['audience-convocation-des-temoins']['audienceId'] = id;
	return rest.search('audience/convocation-des-temoins', filter)
}
const getAudienceMandatDArretUploadUrl = (id) => {
    return getServiceUri() + 'audience/mandat-d-arret' + '/' + id;
};
const handleAudienceMandatDArretClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'audience/mandat-d-arret' + '/' + id + '/' + token;
};
const getAudienceMandatDArretPreviewUrl = (id) => {
	return getServiceUri() + 'audience/mandat-d-arret' + '/preview/' + id + '/';
};
const updateAudienceMandatDArretData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'audience/mandat-d-arret' + '/' + data.id, "PUT", filter);
};
const handleAudienceMandatDArretDelete = (id) => {
	rest.delete('audience/mandat-d-arret', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadAudienceMandatDArretData = async (id) => {
	let filter = {and: true};
	filter['audience-mandat-d-arret'] = {};
	filter['audience-mandat-d-arret']['audienceId'] = id;
	return rest.search('audience/mandat-d-arret', filter)
}
const getAudienceMandatDeDepotUploadUrl = (id) => {
    return getServiceUri() + 'audience/mandat-de-depot' + '/' + id;
};
const handleAudienceMandatDeDepotClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'audience/mandat-de-depot' + '/' + id + '/' + token;
};
const getAudienceMandatDeDepotPreviewUrl = (id) => {
	return getServiceUri() + 'audience/mandat-de-depot' + '/preview/' + id + '/';
};
const updateAudienceMandatDeDepotData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'audience/mandat-de-depot' + '/' + data.id, "PUT", filter);
};
const handleAudienceMandatDeDepotDelete = (id) => {
	rest.delete('audience/mandat-de-depot', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadAudienceMandatDeDepotData = async (id) => {
	let filter = {and: true};
	filter['audience-mandat-de-depot'] = {};
	filter['audience-mandat-de-depot']['audienceId'] = id;
	return rest.search('audience/mandat-de-depot', filter)
}
const getAudienceAttachmentsUploadUrl = (id) => {
    return getServiceUri() + 'audience/attachments' + '/' + id;
};
const handleAudienceAttachmentsClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'audience/attachments' + '/' + id + '/' + token;
};
const getAudienceAttachmentsPreviewUrl = (id) => {
	return getServiceUri() + 'audience/attachments' + '/preview/' + id + '/';
};
const updateAudienceAttachmentsData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'audience/attachments' + '/' + data.id, "PUT", filter);
};
const handleAudienceAttachmentsDelete = (id) => {
	rest.delete('audience/attachments', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};
const loadAudienceAttachmentsData = async (id) => {
	let filter = {and: true};
	filter['audience-attachments'] = {};
	filter['audience-attachments']['audienceId'] = id;
	return rest.search('audience/attachments', filter)
}

export const form2dto = (formData, dto) => {
    if (formData.calendar.time != null && typeof(formData.calendar.time) != 'string') {
        const timeDate = new Date(formData.calendar.time)
        let timeHour = timeDate.getHours() < 10 ? '0' + timeDate.getHours() : timeDate.getHours();
        let timeMinutes = timeDate.getMinutes() < 10 ? '0' + timeDate.getMinutes() : timeDate.getMinutes();
        dto.time = timeHour + ":" + timeMinutes;
    }
    if (formData.calendar.date != null && typeof(formData.calendar.date) != 'string') {
        let dateAsDate = new Date(formData.calendar.date);
        dto.date = dateAsDate.getFullYear() + "-" + ('0' + (dateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + dateAsDate.getDate()).slice(-2);
    }
    customAudienceForm2Dto(formData, dto);
}

export const dto2form = (dto) => {
    let form = dto;
    form.calendar = null;
    if (dto.date !== null) {
        form.calendar = {}
        form.calendar.date = new Date(dto.date[0] + "-" + dto.date[1] + "-" + dto.date[2]);
        if (dto.time !== null) {
            let h = (dto.time[0] < 10)?'0'+dto.time[0]:dto.time[0];
            let m = (dto.time[1] < 10)?'0'+dto.time[1]:dto.time[1];
            form.calendar.time = new Date("2017-05-29" + "T" + h + ":" + m + ":" + "00");
        }
    }
    
    customAudienceDto2form(dto, form);
    return form;
}

const getButtons = (id, onFinish) => {
    return (
        <>
            <div className="row">
                <div className="col-md-6">
                    <button style={{ minWidth: '5rem' }} type="submit">
                        {t`Submit`}
                    </button>
                </div>
            </div>
        </>
    )
}

class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
	    let AudienceForm = createFormComponent(audienceFields);
		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={() => this.handleDontSave(this.props.id)}
			/>
			<AudienceForm ref={this.myRef} key={this.props.key} loadData={this.props.loadData} onSubmit={this.props.onSubmit} id={this.props.id} buttons={getButtons} />
		</>
		)
	}
}

export const displayNewAudienceForm = (gui, formData) => {
	const readNewAudienceForm = (onFinish) => () => {
        let uuid = uuidv4();
        return {
            uuid, view: () => <FormComponent key={uuid} loadData={async () => buildEmptyObject(audienceFields)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):save}/>
        };
    }
    let data;
    if (!formData) {
        data = buildEmptyObject(audienceFields);
        data.draft = true;
        save(data).then((response) => {
            gui.goTo(readNewAudienceForm(), response)
        });
    } else {
        data = _.clone(formData);
        data.draft = true;
        form2dto(formData, data);
        if (formData.image) {
            if (!formData.image.isEmpty) {
                let base64Image = formData.image.url;
                fetch(base64Image)
                .then(res => res.blob())
                .then(blob => {
                    let faceMimeType = "image/png";
                    data.face = blob;
                    data.faceMimeType = faceMimeType;
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        data.image.url = reader.result;
                        save(data).then((response) => {
                            gui.goTo(readNewAudienceForm(), response)
                        });
                    };
                    reader.readAsDataURL(blob);
                });
            } else {
                save(data).then((response) => {
                    gui.goTo(readNewAudienceForm(), response)
                });
            }
        } else {
            save(data).then((response) => {
                gui.goTo(readNewAudienceForm(), response)
            });
        }
    }
}

export const displayReadAudienceForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} loadData={async () => loadFormData(id)} onSubmit={(onFinish)?(data) => update(data).then(() => onFinish()):update} id={id}/>
	};
}

const buildEmptyObject = (fields) => {
	const empty = {};
	for (let i = 0; i < fields.length; i++) {
		let field = fields[i];
		switch (field.type) {
			case ("text"):
				empty[field.name] = "";
				break;
			case ("number"):
				empty[field.name] = "";
				break;
			case ("checkbox"):
				empty[field.name] = false;
				break;
			case ("timestampz"):
				empty[field.name] = '';
				break;
			case ("date"):
				empty[field.name] = null;
				break;
			case ("select"): // dynamic lists, loaded from the backend
				empty[field.name] = '';
				break;
			case ("list"): // static lists, hardcoded
				empty[field.name] = '';
				break;
			case ("password"):
				empty[field.name] = '';
				break;
            case ("image"):
                empty[field.name] = {x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/public/avatar.png', isEmpty: true};
                break;
		}
	}
	return empty;
}

const loadFormData = async (id) => {
	return await rest.read('audience', id).then(response => {
		let form = dto2form(response);
		return form;
	})
}

const save = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['audience'].form2dto(data);
	form2dto(formData, dto);
	try {
        return rest.request(getServiceUri() + 'audience/new-document', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
				return response;
		});
    } catch (err) {
        alert(err);
    }
}

const update = async (formData) => {
	let data = _.clone(formData);
	form2dto(formData, data)
	try {
        return rest.request(getServiceUri() + 'audience/edit-document', 'POST', data).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
		});
    } catch (err) {
        alert(err);
    }
}
