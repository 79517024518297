import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_ADVANCED_SEARCH } from "../../../../auto/js/events/Gui";
import {  pojoMetadata } from "../../../../auto/js/metadata";
import {  MTLB_STATUS_WAIT_FOR_PV, MTLB_STATUS_CASE_OPENED, MTLB_STATUS_READY_FOR_APPROVAL, MTLB_STATUS_REJECTED, MTLB_STATUS_PV_SUBMITTED, MTLB_STATUS_CLOSED, MTLB_STATUS_ARCHIVED } from "../../../../auto/js/metadata/MtlbStatus";
import { t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { registrePoursuiteListColumns, getData, getMtlbTypeList, getOrderBy, countData, filterData, getErrorList, transformAdvancedSearchData, advancedSearchfields } from "../../../../main/js/lists/registrePoursuite/RegistrePoursuiteListCommon.jsx"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import { displayReadApprovedRegistrePoursuiteForm } from "../../../../auto/js/forms/registrePoursuite/ApprovedRegistrePoursuiteForm";
import { displayReadPendingRegistrePoursuiteForm } from "../../forms/registrePoursuite/PendingRegistrePoursuiteForm";
import { displayReadReadyRegistrePoursuiteForm } from "../../forms/registrePoursuite/ReadyRegistrePoursuiteForm";
import { displayReadRejectedRegistrePoursuiteForm } from "../../forms/registrePoursuite/RejectedRegistrePoursuiteForm";


const RegistrePoursuiteListPage = ({uuid, gui, searchData}) => {
    const [waitForPv, setWaitForPv] = useState(false);
    const [caseOpened, setCaseOpened] = useState(false);
    const [pvSubmitted, setPvSubmitted] = useState(false);
    const [closed, setClosed] = useState(false);
    const [judged, setJudged] = useState(false);
    const [errorList, setErrorList] = useState([]);
    const[selectAllStatus, setSelectAllStatus] = useState(false);
    const[selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(undefined);

    let RegistrePoursuiteTable = createTableComponent(registrePoursuiteListColumns);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("waitForPv"):
                setWaitForPv(value.target.checked);
                break;
            case ("caseOpened"):
                setCaseOpened(value.target.checked);
                break;
            case ("pvSubmitted"):
                setPvSubmitted(value.target.checked);
                break;
            case ("closed"):
                setClosed(value.target.checked);
                break;
            case ("judged"):
                setJudged(value.target.checked);
                break;
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
            	setWaitForPv(value.target.checked);
            	setCaseOpened(value.target.checked);
                setPvSubmitted(value.target.checked);
                setClosed(value.target.checked);
                setJudged(value.target.checked);
				break;
        }
    }
    
    if (advancedSearchData != searchData)
		setAdvancedSearchData(searchData);
    
    const buildData = async (query) => {
        let filter = query;
        let data;
        let mtlbStatusList = [];
        let tags =[];
        if (waitForPv)
            mtlbStatusList.push(MTLB_STATUS_WAIT_FOR_PV);
        if (caseOpened)
            mtlbStatusList.push(MTLB_STATUS_CASE_OPENED);
        if (pvSubmitted)
            mtlbStatusList.push(MTLB_STATUS_PV_SUBMITTED);
        if (closed)
            mtlbStatusList.push(MTLB_STATUS_CLOSED);
        if (judged)
            mtlbStatusList.push(MTLB_STATUS_ARCHIVED);
        if (!waitForPv && !caseOpened && !pvSubmitted && !closed && !judged)
            mtlbStatusList = [MTLB_STATUS_WAIT_FOR_PV, MTLB_STATUS_CASE_OPENED, MTLB_STATUS_PV_SUBMITTED, MTLB_STATUS_CLOSED, MTLB_STATUS_ARCHIVED]
        errorList.forEach(element => {
            tags.push( {content: element} )
        });
        filter["registre-poursuite-document"] = { caseStatusList: mtlbStatusList};
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["registre-poursuite"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["registre-poursuite-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            return filterData(response).then(data => {
                return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
            })
        });
    }
    
    const onErrorFacetChange = (key, value) => {
        let list = _.clone(errorList);
        if (key === "selectAllError") {
            if (value.target.checked)
                list = selectAllErrors();
            else
                list = [];
            setErrorList(list);
            setSelectAllError(value.target.checked)
        }
        if (value.target.checked && !list.includes(key)) {
            list.push(key);
            setErrorList(list);
        }
        else if (!value.target.checked && list.includes(key)) {
            let index = list.indexOf(key);
            list.splice(index, 1);
            setErrorList(list);
        }
    }
    
    const getErrorCodeFacets = () => {
      let list = [{key: "selectAllError", value: selectAllError, label: t`select/deselect all`, separator: true}];
        getErrorList().forEach(error => {
            list.push({key: error, value: errorList.includes(error), label: t(error)});

        });
        return list;
    }
    
    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }
    
    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <Facets title={t`Folder Status`} facets={[{key: "selectAllStatus", value: selectAllStatus, separator: true}, { key: "waitForPv", value: waitForPv }, { key: "pvSubmitted", value: pvSubmitted}, { key: "caseOpened", value: caseOpened }, { key: "closed", value: closed }, { key: "judged", value: judged }]} facetsComponents={(key, value) => buildFacets(key, value, t(key), onFacetChange)} />
            <Facets title={t`Errors`} facets={getErrorCodeFacets()} facetsComponents={(key, value) => buildFacets(key, value, t(key), onErrorFacetChange)} />
            <RegistrePoursuiteTable key={uuid} loadData={async (query) => buildData(query)}  actions={getTableActions(gui)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )


}

export const displayRegistrePoursuiteList = (gui,advancedSearchData) => {  
    const readRegistrePoursuiteList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RegistrePoursuiteListPage gui={gui} key={uuid}  searchData={advancedSearchData}/>
		};
	}
	gui.goTo(readRegistrePoursuiteList)
}

export const displayRegistrePoursuiteAdvancedSearch = (gui) => {
    let advancedSearchFilters = {fields: advancedSearchfields, name: t`RegistrePoursuite`, onSubmit: (filter) => onAdvancedSearchSubmit(filter, gui)};
	OPEN_ADVANCED_SEARCH.publish(advancedSearchFilters);
}

const onAdvancedSearchSubmit = (filter, gui) => {
    displayRegistrePoursuiteList(gui, transformAdvancedSearchData(filter));
}

const selectAllErrors = () => {
    let list = ["selectAllError"];
    getErrorList().forEach(error => {
            list.push(error);
    });
    return list;
}

const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
                tooltip: t`Edit`,
                onClick: (event, rowData) => {
                    switch(rowData.caseStatus) {
                         case (MTLB_STATUS_WAIT_FOR_PV):
                        	gui.goTo(displayReadPendingRegistrePoursuiteForm(() => gui.goTo(displayRegistrePoursuiteList(gui))), rowData.id, null);
                            break;
                        case (MTLB_STATUS_PV_SUBMITTED):
                            gui.goTo(displayReadReadyRegistrePoursuiteForm(() => gui.goTo(displayRegistrePoursuiteList(gui))), rowData.id, null);
                            break;
                        case (MTLB_STATUS_CASE_OPENED):
                            gui.goTo(displayReadApprovedRegistrePoursuiteForm(), rowData.id, null);
                            break;
                        case (MTLB_STATUS_CLOSED):
                            gui.goTo(displayReadApprovedRegistrePoursuiteForm(), rowData.id, null);
                            break;
                        case (MTLB_STATUS_ARCHIVED):
                            gui.goTo(displayReadApprovedRegistrePoursuiteForm(), rowData.id, null);
                            break;
                    }
                }
        }
    )
    return actions;   
}
