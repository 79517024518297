import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateRegistrePoursuiteForm, loadRegistrePoursuiteFormData } from "./RegistrePoursuiteApi";
import { registrePoursuiteFields } from "../../../../main/js/forms/registrePoursuite/RegistrePoursuiteFormCommon.jsx";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";
import { Section } from "../../../../auto/js/widgets/Section";

import { createTableComponent } from "../../widgets/TableComponent";
import { whoami } from "../../users/UserInfo";

	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		let RegistrePoursuiteForm = createFormComponent(registrePoursuiteFields(whoami()));

		return (
		<>
			<RegistrePoursuiteForm key={this.props.key} loadData={async () => loadRegistrePoursuiteFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => null} />
		</>
		)
	}

}

	

export const displayReadApprovedRegistrePoursuiteForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} />
	};
}

const loadFormData = async (id) => {
	return await loadRegistrePoursuiteFormData(id);
}
