import React, { useCallback } from 'react';

import styled from 'styled-components';
import { createBrowserComponent } from './Browser';
import { RibbonTab, setContextualOptions } from '../widgets';
import { OPEN_SIDE_PANEL } from '../events/Gui';

import { ToolBar } from '../widgets/ToolBar';
import { SidePanelComponent } from '../widgets/SidePanelComponent';
import { AdvancedSearchComponent } from '../../../auto/js/widgets/AdvancedSearchComponent';
import CreateOrSearchComponentClass from '../widgets/CreateOrSearchComponentClass';

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    height: calc(100vh - 278px);
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
`;

export class RibbonBrowser extends createBrowserComponent() {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
        }
    }

    
    
    render() {
        
        return (
            <>
            <ToolBar languages={this.props.supportedLanguages}/>
			<RibbonTab gui={this}/>
			<Main>
			{this.buildDesktop()}
            <SidePanelComponent />
			</Main>
			<AdvancedSearchComponent/>
            <CreateOrSearchComponentClass />
            </>
        )
    }
}