import React from "react";

import { MTLB_TYPE_COMPLAINT } from "../../../../auto/js/metadata/MtlbType";
import { ComplainantContactPreferences, COMPLAINANT_CONTACT_PREFERENCES_EMAIL } from "../../../../auto/js/metadata/ComplainantContactPreferences"
import { IncidentCategory, INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN } from "../../../../auto/js/metadata/IncidentCategory"
import { buildEmptyObject } from "../../../../auto/js/widgets/FormComponent";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { DELETE_ATTACHMENT_EVENT, PRINT_EVENT } from "../../../../auto/js/events/Gui";
import { Section } from "../../../../auto/js/widgets/Section";
import TemplateComponent from 'react-mustache-template-component';
import { setContextualOptions } from "../../../../auto/js/widgets";
import { createRecord, getRecordsOptions, readRecord } from "../../utils";
import AutocompleteDynamicListSelectorField from "../../widgets/AutocompleteDynamicListSelectorField";
import { whoami } from "../../../../auto/js/users/UserInfo";

const incidentCategory = {
	1: "CONSUMER_RIGHTS",
	2: "ENVIRONMENTAL_CONCERNS",
	3: "PUBLIC_SAFETY",
	4: "GOVERNMENTAL_CONCERN"
}

const complainantContactPreferences = {
	1: "EMAIL",
	2: "PHONE"
}

export const complaintMtlbFields = (whoami) => {return [
	{name: "plaignants", type: "custom", x:1, y:2, layout: "col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null, component: (name, disabled) => <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Plaignants`} />},
	{name: "Case", type: "custom", x: 1, y: 7, layout: "col-md-12", component: (name, disabled) => <Section name={name} /> },
	{name: "id", type: "number", x: 1, y: 8, layout: "col-md-6", disabled: true },
	{name: "legacyId", type: "text", x: 1, y: 9, layout: "col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null },
	{name: "incidentCategory", type: "select", x:1, y: 10, layout:"col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null, metadata: () => incidentCategory},
	{ name: "incidentDate", type: "date", x:1, y:11,  layout:"col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null},
	{ name: "incidentTime", type: "time", x:2, y:11,  layout:"col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null },
    {name: "subjectOfComplaint", type:"text", x:1, y:17, layout:"col-md-6"},
    {name: "victimes", type: "custom", x:1, y:19, layout: "col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null, component: (name, disabled) => <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Victimes`} />},
    {name: "misCauses", type: "custom", x:1, y:21, layout: "col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null, component: (name, disabled) => <AutocompleteDynamicListSelectorField time={1000} readEntry={readRecord} createEntry={(data, callback, onCancel) => createRecord(data, callback, onCancel)} searchRequest={async (filter) => getRecordsOptions(filter)} name={name} readOnly={disabled} label={t`Mis en Causes`} />},
	{name: "description", type:"text", x:1, y:23, layout:"col-md-6", ...(whoami)?whoami.roles.includes('GREFFIER_ADMIN') && {disabled: true}:null,},
	{name: "attachments", label: "Pièces jointes de l'affaire", type:"file", x:1, y:24, layout:"col-md-12",
		uploadUrl: (id) => getUploadUrl(id), 
		previewUrl:  (id) => getPreviewUrl(id),
		loadData: async (id) => loadAttachment(id), 
		handleDelete:(id) => handleDelete(id), 
		handleClick: (id) => handleClick(id),
		updateFileData: (data) => updateFileData(data)
	},
];}

const getUploadUrl = (id) => {
	return getServiceUri() + 'complaint-mtlb/attachments' + '/' + id;
}

const getPreviewUrl =  (id) => {
	return getServiceUri() + 'complaint-mtlb/attachments' + '/preview/' + id + '/';
}

const updateFileData = (data) => {
	let filter = {name: data.fileName, description: data.description};
	rest.request(getServiceUri() + 'complaint-mtlb/attachments' + '/' + data.id, "PUT", filter);
}

const handleClick = async (id) => {
	const token = await rest.getToken(getServiceUri() + 'token/get-auth-code');
	window.location = getServiceUri() + 'complaint-mtlb/attachments' + '/' + id + '/' + token;
};
const handleDelete = (id) => {
	rest.delete('complaint-mtlb/attachments', id).then(() => {
		DELETE_ATTACHMENT_EVENT.publish(id)
	});
};

const loadAttachment = async (id) => {
	let filter = {and: true};
	filter['complaint-mtlb-attachments'] = {};
	filter['complaint-mtlb-attachments']['complaintMtlbId'] = id;
	return rest.search('complaint-mtlb/attachments', filter)
}

const printTemplate = (data) => () => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={data.templateData} />,
		copyParentStryle: false
	})
}

export const form2dto = (formData, dto) => {
	if (dto.mtlbType === null || !dto.mtlbType)
		dto.mtlbType = MTLB_TYPE_COMPLAINT;
	if (dto.incidentCategory !== null)
//		dto.incidentCategory = INCIDENT_CATEGORY_GOVERNMENTAL_CONCERN;
//	else
		dto.incidentCategory = incidentCategory[dto.incidentCategory];
	if (dto.complainantContactPreferences === null || !dto.complainantContactPreferences)
		dto.complainantContactPreferences = COMPLAINANT_CONTACT_PREFERENCES_EMAIL;
	else
		dto.complainantContactPreferences = complainantContactPreferences[dto.complainantContactPreferences]
//	if (dto.complainantIdCardNumber === null || ! dto.complainantIdCardNumber)
//		dto.complainantIdCardNumber = 0;
	if (formData.incidentTime != null && typeof(formData.incidentTime) != 'string') {
		const date = new Date(formData.incidentTime)
		let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.incidentTime = hour + ":" + minute;
	}
	if (formData.victimes) {
		rest.purge('victimes', formData.id).then(() => {
			Object.keys(formData.victimes).forEach(element => {
				let data = {folderId: formData.id, recordsId: element}
				rest.create('victimes', data);
			});
		})
		
	}

	if (formData.misCauses) {
		rest.purge('mis-cause', formData.id).then(() => {
			Object.keys(formData.misCauses).forEach(element => {
				let data = {folderId: formData.id, recordsId: element}
				rest.create('mis-cause', data);
			});
		})
	}
		

	if (formData.plaignants) {
		rest.purge('plaignants', formData.id).then(() => {
			Object.keys(formData.plaignants).forEach(element => {
				let data = {folderId: formData.id, recordId: element}
				rest.create('plaignants', data);
			});
		})
	}
		
		
}

export const dto2form = (dto) => {
	let form = dto;
	if (form.complainantIdCardNumber == 0)
		form.complainantIdCardNumber = null
	if (dto.incidentTime !== null) {
		dto.incidentTime = new Date().setHours(dto.incidentTime[0], dto.incidentTime[1]);
	}
	if (dto.incidentCategory !== null)
		form.incidentCategory = IncidentCategory[dto.incidentCategory];
	if (dto.complainantContactPreferences !== null)
		form.complainantContactPreferences = ComplainantContactPreferences[dto.complainantContactPreferences];

	rest.read('forms/avis-de-classement/registre-arrivee', form["id"]).then(async avisDeClassement => {
		dto.mtlbStatus === "CLOSED" && setContextualOptions({
			"folders": {
				submenu: {
					"actions": {
						label: "Actions",
						options: {
							"print-avid-de-classement": {
								label: "Imprimer Avis De Classement",
								do: printTemplate(avisDeClassement)
							}
						}
					}
				}
			}
		})
	})
	
  return form;
}

export const buildComplaintMtlbFormEmptyObject  = () => {
	return buildEmptyObject(complaintMtlbFields(whoami()));
}

export const newComplaintMtlbFormForm2Dto = (form, dto) => {
	
}