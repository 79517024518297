import React, { useState } from "react";
import { Chip, TextField, Autocomplete, FormControl, InputLabel } from '@mui/material';
import RemoveCircleOutlineSharp from '@mui/icons-material/RemoveCircleOutlineSharp';
import { useField } from "formik";
import { loadingIcon } from "../../../auto/js/loadingIcon";

/**
 * Transforms the input as described in the api to the form required by the
 * GUI components
 * 
 * @param allOptions The options object as provided to the Widget
 * @param list The list of IDs of the options displayed as chips
 * @returns An array of objects in the form {id: number, label: str}
 *    that stores the options that are referenced in the list
 */
function preprocessInput(allOptions, ) {
    let options = [];
    for (const [key, value] of Object.entries(allOptions)) {
        options.push({
            "id": key,
            "label": value
        });
    }

    return options;
}

/**
 * 
 * @param options object in the form {id1: label1, id2: label2...} representing all possible options
 * for the auto complete
 * @param props must contain a "name" property
 */
function AutocompleteOneSelection(props) {
    const [field, meta, helpers] = useField(props.name);
    const [options, setOptions] = useState(undefined);
    const value = meta.value;
    const setValue = helpers.setValue;

    if(props.options)
      setOptions(props.options);

    if(!options) {
      if(props.loadOptions)
        props.loadOptions().then(response => {
          setOptions(response);
        })
      return loadingIcon
    }

    return (
        <div>
          <FormControl variant="standard">
                <InputLabel shrink htmlFor={props.name}>
                    {props.label}
                </InputLabel>
           <Autocomplete
            className={props.layout}
            id="tags-standard"
            readOnly={props.readOnly}
            filterSelectedOptions
            options={preprocessInput(options)}
            getOptionLabel={option => (option)?option.label:""}
            onChange={(e, newValue) => {
              if (newValue !== null)
                setValue(newValue.id);
            }}
            aria-label={props.label}
            value={(value !== null)?{"id":value, "label": options[value]}:""}
            renderTags={() => {}}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                placeholder={props.label}
                label={props.label}
                margin="normal"
                fullWidth
                InputLabelProps={{ shrink: false }}
              />
            )}
          />
          </FormControl>
        </div>
      )
}

export default AutocompleteOneSelection;