import * as React from 'react';

import { useField } from "formik";

import DateFnsUtils from '@date-io/date-fns';
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { t } from '../services';
import { FormControl, InputLabel } from '@mui/material';

export function TimeInput(props) {
    const [field, meta, helpers] = useField(props.name);
    const { value } = meta;
    const { setValue } = helpers;
    const handleChange = newValue => {
      setValue(newValue);
    };

    return (
      <FormControl variant="standard">
        <InputLabel shrink htmlFor={props.name}>
          {props.label}
        </InputLabel>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
            style={{marginTop: "16px", marginBottom: "8px"}}
            id={props.id}
//           label={props.label}
            value={value}
            autoOk
            onChange={handleChange}
            disabled={props.disabled}
            okLabel={t`ok`}
            cancelLabel={t`cancel`}
            InputLabelProps={{shrink: false}}
            />
      </MuiPickersUtilsProvider>
      </FormControl>
    );
}
