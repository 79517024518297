import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { CAN_I_SWITCH, } from '../../events/Gui';
import { AlertDialog, setContextualOptions } from "../../widgets";
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { approveReadyRegistrePoursuiteForm, rejectReadyRegistrePoursuiteForm, loadRegistrePoursuiteFormData, poursuivreRegistrePoursuit, classerRegistrePoursuit } from "./RegistrePoursuiteApi";
import { registrePoursuiteFields } from "../../../../main/js/forms/registrePoursuite/RegistrePoursuiteFormCommon.jsx";
import { whoami } from "../../users/UserInfo";


class FormComponent extends React.Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef()
		CAN_I_SWITCH.pickUpThePhone(this.listen);
		this.state = {
			closeRequested: undefined
		}
    }

	listen = (closeMe) => {
		if (!this.isDirty())
			closeMe(true);
		this.setState({closeRequested: closeMe})
	}

	isDirty = () => {
		return this.myRef.current.isDirty();
	}

	handleDialogCancel = () => {
		this.state.closeRequested(false);
		this.setState({closeRequested: undefined});
	}

	handleSave = () => {
		this.myRef.current.save().then(() => {
			this.state.closeRequested(true);
		});
	}

	handleDontSave = () => {
		this.state.closeRequested(true);
	}

	render() {
		let RegistrePoursuiteForm = createFormComponent(registrePoursuiteFields(whoami()));

		return (
		<>
			<AlertDialog
				title={t`Save your changes ?`}
				open={(this.state.closeRequested && this.isDirty())?true:false}
				handleClose={this.handleDialogCancel}
				noAgree={true}
				save={this.handleSave}
				dontSave={this.handleDontSave}
			/>
			<RegistrePoursuiteForm ref={this.myRef} key={this.props.key} loadData={async () => loadRegistrePoursuiteFormData(this.props.id)} readOnly onSubmit={(this.props.onFinish)?() => approve(this.props.id).then(() => this.props.onFinish()):() => approve(this.props.id)} id={this.props.id} buttons={() => getButtons(this.props.id, this.props.onFinish)} />
		</>
		)
	}

}


export const displayReadReadyRegistrePoursuiteForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} onFinish={onFinish}/>
	};
}

const approve = async (id) => {
	return approveReadyRegistrePoursuiteForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case Approved`, "success");
	});
;
}

const reject = async (id) => {
    return rejectReadyRegistrePoursuiteForm(id).then((response) =>{
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error")
		else
			showNotification(t`Case rejected`, "success");
	});
;
}

const poursuivre = async (id) => {
	return poursuivreRegistrePoursuit(id);
}

const classer = async (id) => {
	return classerRegistrePoursuit(id);
}

const loadFormData = async (id) => {
	return await loadRegistrePoursuiteFormData(id);
}
// in form button = true
/* const getButtons = (id, onFinish) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
			<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={(onFinish)?() => poursuivre(id).then(() => onFinish()):() => poursuivre(id)}> {t`Poursuivre`} </button>
		</div>
		<div className="col-md-6">
        	<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={(onFinish)?() => classer(id).then(() => onFinish()):() => classer(id)}> {t`Classer`} </button>
		</div>
	</div>
    </>
    )
} */
// else integrate in the menu (addButtonsToMenu)

/* const getButtons = (id, onFinish) => {
	setContextualOptions({
		"rp": {
			"rp": {
				submenu: {
					"actions": {
						label: "Actions",
						options: {
							"poursuivre": {
								label: "Poursuivre",
								do: (onFinish)?() => poursuivre(id).then(() => onFinish()):() => poursuivre(id)
							},
							"classer": {
								label: "Classer",
								do: (onFinish)?() => classer(id).then(() => onFinish()):() => classer(id)
							}
						}
					}
				}
			}
		}
	})
} */