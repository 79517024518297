import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT, PRINT_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateSoitTransmisForm, loadSoitTransmisFormData } from "./SoitTransmisApi";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";
import { Section } from "../../../../auto/js/widgets/Section";

import { createTableComponent } from "../../widgets/TableComponent";
import { loadFormData, soitTransmisFields } from "./NewSoitTransmisForm";
import TemplateComponent from "react-mustache-template-component";
import { geoDataMetadataLoader } from "../../metadata/GeoDataMetadataLoader";

const unit = {
	1: "POL",
	2: "GEN",
	3: "GAC",
}
	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		let SoitTransmisForm = createFormComponent(soitTransmisFields);
		return (
		<>

			<SoitTransmisForm key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => getButtons(this.props.id)} />
		</>
		)
	}

}

	

export const displayReadApprovedSoitTransmisForm = (onFinish) => (id) => {
	let uuid = uuidv4();
	return {
		uuid, view: () => <FormComponent key={uuid} id={id} />
	};
}

const getButtons = (id) => {
	return (
    <>
	<div className="row">
		<div className="col-md-6">
			<button style={{ minWidth: '5rem' }} type="button" className={'reject-button'} onClick={() => printSoitTransmis(id)}> {t`Imprimer`} </button>
		</div>
	</div>
    </>
    )
}

export const printSoitTransmis = (id) => {
	rest.read('forms/soit-transmis', id).then(async soitTransmis => {
		printTemplate(soitTransmis)
	})
}

const printTemplate = (data) => {
	PRINT_EVENT.publish({
		content: <TemplateComponent template={data.template} data={dto2form(data.templateData)} />,
		copyParentStryle: false
	})
}

const dto2form = (dto) => {
	let form = dto; 
	if (dto.place != null)
		form.place = formatLocation(dto.place);
	if (dto.destination != null)
		form.destination = t(unit[dto.destination]);
	return form;
}

const formatLocation = (location) => {
	let destinationPlace = [];
	if (location != null) {
		let destinationPlaceComponents = location.split(".");
		let place = location;
		destinationPlace.push(
			(geoDataMetadataLoader.getArea(place))?" " + t(geoDataMetadataLoader.getArea(place).name):t("INVALID DATA ")
		)
		for (let i = 0; i < destinationPlaceComponents.length - 1 ; i ++) {
			let parentAreaId = place.substring(0, place.lastIndexOf("."));
			place = parentAreaId;
			destinationPlace.push(
				(geoDataMetadataLoader.getArea(parentAreaId))?" " + t(geoDataMetadataLoader.getArea(parentAreaId).name):t("INVALID DATA ")
			)                
		}
	}
	return destinationPlace;
}
