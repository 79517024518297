import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import {  getDjServicesUrl, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    getErrorList,
    transformAdvancedSearchData,
    advancedSearchfields,
    getRowActions
} from "../../../../main/js/lists/records/RecordsListCommon.jsx";


export const recordsListFields = [
    {
        title: "ID",
        field: "id",
    },
    {
        title: "Prénom",
        field: "firstname",
    },
    {
        title: "Nom",
        field: "lastname",
    },
    {
        title: "Numéro d'identité",
        field: "idNumber",
    },
    {
        title: "Actions",
        field: "actions",
        render: (rowData) => getRowActions(rowData)
    }
]

const RecordsListPage = ({uuid, gui, searchData}) => {
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let RecordsTable = createTableComponent(recordsListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["records-document"] = {}
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["records"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["records-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response, gui);
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>
            <RecordsTable key={uuid} loadData={async (query) => buildData(query)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displayRecordsList = (gui) => {
	const readRecordsList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <RecordsListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readRecordsList)
}

export const getData = async (filter) => {
    return await rest.request(getDjServicesUrl() + "records/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getDjServicesUrl() + "records/count-document/", "POST", filter);
}

export const filterData = (defaultRows, gui) => {
    const newRows = [];
    defaultRows.forEach((row) => {
        let faceUrl = null;
        row.face = (faceUrl != null) ? faceUrl : "/public/avatar.png";
        row.gui = gui;
        newRows.push(row);
    });
    return newRows;
}