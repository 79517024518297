export const COMPLAINT_MTLB_ORDER_BY_ID = "ID";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINT_RECORD_ID = "COMPLAINT_RECORD_ID";
export const COMPLAINT_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
export const COMPLAINT_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_FIRST_NAME = "COMPLAINANT_FIRST_NAME";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_LAST_NAME = "COMPLAINANT_LAST_NAME";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_PHONE_NUMBER = "COMPLAINANT_PHONE_NUMBER";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_EMAIL_ADDRESS = "COMPLAINANT_EMAIL_ADDRESS";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_ADDRESS = "COMPLAINANT_ADDRESS";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_ID_CARD_NUMBER = "COMPLAINANT_ID_CARD_NUMBER";
export const COMPLAINT_MTLB_ORDER_BY_INCIDENT_CATEGORY = "INCIDENT_CATEGORY";
export const COMPLAINT_MTLB_ORDER_BY_INCIDENT_DATE = "INCIDENT_DATE";
export const COMPLAINT_MTLB_ORDER_BY_INCIDENT_TIME = "INCIDENT_TIME";
export const COMPLAINT_MTLB_ORDER_BY_JURISDICTION = "JURISDICTION";
export const COMPLAINT_MTLB_ORDER_BY_COMPLAINANT_CONTACT_PREFERENCES = "COMPLAINANT_CONTACT_PREFERENCES";
export const COMPLAINT_MTLB_ORDER_BY_DESIRED_OUTCOME = "DESIRED_OUTCOME";
export const COMPLAINT_MTLB_ORDER_BY_EVIDENCE = "EVIDENCE";
export const COMPLAINT_MTLB_ORDER_BY_SUBJECT_OF_COMPLAINT = "SUBJECT_OF_COMPLAINT";
export const COMPLAINT_MTLB_ORDER_BY_SUBJECT_ADDRESS = "SUBJECT_ADDRESS";
export const COMPLAINT_MTLB_ORDER_BY_DESCRIPTION = "DESCRIPTION";
