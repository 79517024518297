import React, { useState } from "react";
import {v4 as uuidv4} from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { OPEN_VIEW_EVENT } from "../../../../auto/js/events/Gui";
import { getServiceUri, pojoMetadata } from "../../../../auto/js/metadata";

import { rest, t } from "../../../../auto/js/services";
import { createTableComponent } from "../../../../auto/js/widgets/TableComponent"
import { Checkbox, FormControlLabel } from "@mui/material";
import Facets from "../../widgets/Facets";
import {
    getOrderBy,
    getErrorList,
    transformAdvancedSearchData,
    advancedSearchfields,
    getRowActions
} from "../../../../main/js/lists/soitTransmis/SoitTransmisListCommon.jsx";


const soitTransmisListFields = [
    {
        title: "Numéro de série",
        field: "id",
    },
    {
        title: "BP",
        field: "bp",
    },
    {
        title: "Numéro RA",
        field: "ra",
    },
    {
        title: "Téléphone",
        field: "phoneNumber",
    },
    {
        title: "Actions",
        field: "actions",
        render: (rowData) => getRowActions(rowData)
    }
]

const SoitTransmisListPage = ({uuid, gui, searchData}) => {
    const [errorList, setErrorList] = useState([]);
    const [selectAllStatus, setSelectAllStatus] = useState(false);
    const [selectAllError, setSelectAllError] = useState(false);
    const [advancedSearchData, setAdvancedSearchData] = useState(searchData);
    let SoitTransmisTable = createTableComponent(soitTransmisListFields);

    const onFacetChange = (key, value) => {
        switch(key) {
            case ("selectAllStatus"):
            	setSelectAllStatus(value.target.checked);
				break;
        }
    }
    const buildData = async (query) => {
        let filter = query;
        let data;
        filter["soit-transmis-document"] = {}
        filter['and'] = true;
        filter["orderBy"] = getOrderBy();
        if (advancedSearchData)
            filter["query"] = advancedSearchData;
        filter.orderDirection = null;
        filter.offset = query.page * query.pageSize;
        if (query.search && query.search!='') {
            pojoMetadata["soit-transmis"].columns.forEach(element => {
                if(element.type=='text' ){
                    filter["soit-transmis-document"][element.key]= query.search;
                }
            });
            filter['and'] = false;
            filter.fuzziness = "AUTO";
        }
        return await getData(filter).then(response => {
            data = filterData(response, gui);
            return countData(filter).then((count) => {return {data: data, totalCount: count, page: query.page}})
        });
    }

    const onAdvancedSearchSubmit = (data) => {
        let dto = transformAdvancedSearchData(data);
        setAdvancedSearchData(dto);
    }

    const buildFacets = (key, value, label, handleChange) => {
        return (
            <>
            <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={(event) => handleChange(key, event)}
              color="primary"
              value={value}
            />
            }
            label={label}
            style={{width: '100%'}}
            />
            </>
        )
    }

    return (
        <div>

          <SoitTransmisTable key={uuid} loadData={async (query) => buildData(query)} advancedSearchFields={advancedSearchfields} onSubmit={onAdvancedSearchSubmit}/>
    	</div>
    )
}

export const displaySoitTransmisList = (gui) => {
	const readSoitTransmisList = () => {
    let uuid = uuidv4();
		return {
			uuid, view: () => <SoitTransmisListPage gui={gui} key={uuid} />
		};
	}
	gui.goTo(readSoitTransmisList)
}

/* const getTableActions = (gui) => {
    let actions = [];
    actions.push(
        {
            icon: () => <VisibilityIcon/>,
            tooltip: t`Edit`,
            onClick: (event, rowData) => {
            }
        }
    )
    return actions;
} */

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "soit-transmis/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "soit-transmis/count-document/", "POST", filter);
}

export const filterData = (defaultRows, gui) => {
    const newRows = [];
    defaultRows.forEach((row) => {
        row.gui = gui;
        newRows.push(row);
    });
    return newRows;
}