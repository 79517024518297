import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { buildEmptyObject, createFormComponent } from "../../../auto/js/widgets";
import { DraggableFilterDialog } from "./DraggableFilterComponentUpgrade";
import { t } from "../../../auto/js/services";
import { OPEN_ADVANCED_SEARCH } from "../../../auto/js/events/Gui";
import { useConstructor } from "../../../auto/js/utils";


export function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} style={{ maxWidth: '50%', width: '50%' }} />
    </Draggable>
  );
}

export const AdvancedSearchComponent = (props) => {
    const [formData, setFormData] = useState(undefined)
    const [openAdvancedSearch, setOpenAdvancedSearch] = useState(false);
    const [searchFilters, setSearchFilters] = useState(undefined);

    useConstructor(() => {
      OPEN_ADVANCED_SEARCH.subscribe((filters) => {
        openAdvancedSearchDialog(filters)
      })
    });

    const buildFormData = async () => {
      return formData;
    }

    const openAdvancedSearchDialog = (filters) => {
      setSearchFilters(filters)
      setOpenAdvancedSearch(true)
    }

    const closeAdvancedSearch = () => {
      setOpenAdvancedSearch(false)
    }

    let SearchForm = createFormComponent((searchFilters)?searchFilters.fields:[]);

    const onSubmit = (data) => {
        (searchFilters)?searchFilters.onSubmit(data):null;
        //setFormData(data);
        setOpenAdvancedSearch(false)
    }
    let name = '';
    if (searchFilters)
      name = searchFilters.name;
    return (
        <>
        {<DraggableFilterDialog open={openAdvancedSearch} onClose={closeAdvancedSearch} title={t`Search` + ' ' + name} paperComponent={PaperComponent} 
        description={""} children={
            <SearchForm loadData={formData ? async () => buildFormData() :async () => buildEmptyObject((searchFilters)?searchFilters.fields:[])} buttons={getButtons} onSubmit={onSubmit} />
        }/>}
        </>
    )
}

const getButtons = () => {
    return <button style={{ minWidth: '5rem', position: 'fixed', right: '20px', top: '5px' }} type="submit"> {t`Find`} </button>
  }