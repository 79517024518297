import React from "react";
import { IconButton } from '@mui/material';

import LoadingOverlay from 'react-loading-overlay-ts';

import EditIcon from '@mui/icons-material/Edit';
import { rest, t } from "../../../auto/js/services";
import { createFormComponent } from "../../../auto/js/widgets";
import { whoami } from "../../../auto/js/users/UserInfo";
import { CLOSE_SIDE_PANEL, OPEN_SIDE_PANEL } from "../../../auto/js/events/Gui";
import { audienceFields, dto2form, loadFormData, update } from "../../../auto/js/forms/audience/NewAudienceForm";

export const AudienceSummary = ({...props}) => {
    return(
      <Audience obj={props.obj} columns={['date', 'time']} footerElements= {['created', 'lastModified']} title={"Audience"} gui={props.gui}/>
      )
}

class Audience extends React.Component {
    constructor(props) {
      super(props);
      this.formRef = React.createRef()
      this.state = {
        data: undefined,
        loading: true,
        loadingMessage: ''
      }
      loadFormData(props.obj.data.id).then((data) => {
        this.setState({ data: data, loading: false })
      });
      this.updateData = this.updateData.bind(this);
      props.obj.observable.subscribe(this.updateData)
    }

    transformData = (data) => {
        let transformed = _.clone(data);
       
        if(transformed.date != null)
            transformed.date =  data.date[2] + '/' + data.date[1] + '/' + data.date[0];
        if(transformed.time) {
            let h = data.time[0];
            let m = data.time[1];
            transformed.time = h + ":" + m;
        }
        return transformed
    }

    componentDidUpdate(prevProps) {
        loadFormData(this.props.obj.data.id).then((data) => {
          if (data.id !== this.state.data.id)
            this.setState({ data: data, loading: false })
        });
      }

    buildBody = () => {
        let items = [];
        let colClass = "col-" + Math.round(12/this.props.columns.length)
        this.props.columns.forEach(element => {
            if(this.state.data[element])
                items.push(
                    <div class={colClass}>
                        <div class="results">
                            <h4>{t(element)}</h4>
                            {this.transformData(this.state.data)[element]}
                        </div>
                    </div>
                )
        });
        return items;
    }

    updateData = (data) => {
        this.setState({data: data})
    }

    buildFooter = () => {
        let items = [];
        this.props.footerElements.forEach(element => {
            <p style={{fontStyle: "italic", color: "#898c8e"}}>{t(element)}: {this.state.data[element]}</p>
        });
        return items;
    }

    loadFormData = async () => {
        let form = dto2form(this.state.data);
        return form;
    }

    form2persist = (data) => {
        if (data.calendar.time != null && typeof(data.calendar.time) != 'string') {
            const date = new Date(data.calendar.time)
            let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            data.time = [hour, minute]
        }
        if(data.calendar.date != null && data.calendar.date instanceof Date)
			data.date = [data.calendar.date.getFullYear(), + ('0' + (data.calendar.date.getMonth() + 1)).slice(-2), ('0' + data.calendar.date.getDate()).slice(-2)];
        return data;
    }

    openSidePanel = (id, readOnly) => {
        let Form = createFormComponent(audienceFields);
        OPEN_SIDE_PANEL.publish(
            { body: 
                <Form loadData={async () => this.loadFormData(id)} readOnly={readOnly} id={id} onSubmit={(data) => update(data).then(() => {
                    CLOSE_SIDE_PANEL.publish()
                    this.props.obj.persist(this.form2persist(data));
                    //this.setState({data: data})
                })} buttons={(whoami().roles.includes('GREFFIER_OA'))?()=>null:this.getButtons} />    
            , title: t`Audience` }
        )
    }

    getButtons = () => {
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <button style={{ minWidth: '5rem' }} type="submit">
                            {t`Submit`}
                        </button>
                    </div>
                </div>
            </>
        )
    }

    openEdit = (id) => {
        this.openSidePanel(this.state.data.id)
    }
    
    render() {
        return (
            <>
            <LoadingOverlay
                active={this.state.loading}
                spinner
                text={this.state.loadingMessage}
            >
            {(this.state.data && this.state.data.date)?
            <div className="rv5info" style={{margin: 'auto', marginBottom: '15px'}}>
            <div class="row">
            <div class="results col-md-11" style={{paddingLeft:"2rem"}}><h2>{t(this.props.title)}</h2></div>
            {(!this.props.readOnly) && <div class="col-md-1">
            <IconButton aria-label={t`Edit`} onClick={() => this.openEdit()}>
                <EditIcon />
            </IconButton>
            </div>}
            </div>    
            <div class="row" style={{paddingLeft:"1rem", marginLeft: "0px"}}>
            {this.buildBody()}
            <div class="results" style={{marginRight: "10px", marginLeft: "auto"}}>
                {this.buildFooter()}
            </div>
            </div>
            </div>:
            null
            }
            </LoadingOverlay>
            </>
        )    
    }
}