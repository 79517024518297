import React, { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@material-ui/icons/Visibility';


import { grey } from "@mui/material/colors";
import { displayReadMandatDepotForm } from "../../../../auto/js/forms/mandatDepot/NewMandatDepotForm";
import { displayReadApprovedMandatDepotForm } from "../../../../auto/js/forms/mandatDepot/ApprovedMandatDepotForm";
import { whoami } from "../../../../auto/js/users/UserInfo";


export const getOrderBy = () => {
    return "ID"
}

export const getRowActions = (rowData) => {
    return (
        <Tooltip>
            <IconButton 
                onClick={(event) => {
                        rowData.gui.goTo(displayReadApprovedMandatDepotForm(), rowData.id)
                }}
                size="small"
                sx={{color: grey[900]}}
            >
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}