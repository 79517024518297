import React from "react";
import { GeoDataComponent } from "../../../../auto/js/widgets/GeoDataComponent";

export const customSoitTransmisForm2Dto = (formData, dto) => {

}

export const customSoitTransmisDto2Form = (dto, form) => {

}

export const placeComponentHook = (name, disabled) => {
    return <GeoDataComponent name={name} disabled={disabled} linear={true} default={"48"}/>
}